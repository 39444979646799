import { schema } from 'normalizr'
import { Record } from 'immutable'

import { BookingSchema } from './bookings'
import { CurrencySchema } from './currencies'

export const PaymentSchema = new schema.Entity('payments', {
  booking: BookingSchema,
  payee_currency: CurrencySchema,
  payer_currency: CurrencySchema,
})

export const PaymentRecord = Record({
  id: undefined,
  booking: undefined,
  booking_id: undefined,
  booking_price: undefined,
  discount: undefined,
  event_price: undefined,
  fees: undefined,
  host_expected_amount: undefined,
  metadata: undefined,
  payee_currency: undefined,
  payee_to_payer_conversion_rate: undefined,
  payer_currency: undefined,
  payer_id: undefined,
  payin_reason: undefined,
  payin_status: undefined,
  payout_reason: undefined,
  payout_status: undefined,
  seats: undefined,
  sub_total: undefined,
  total_paid: undefined,
})

export const emptyPayment = new PaymentRecord()
