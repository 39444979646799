import { screenApi } from 'apis'
import { ActionsCreator, mergeMetadata } from '../lib'
import { ConversationSchema } from 'redux/schemas'

export const conversationsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'conversations' })

export const fetchConversations = (query) =>
  conversationsActions.fetch({
    url: '/conversations',
    query,
    schema: { conversations: [ConversationSchema] },
  })

export const fetchConversation = (id, query) =>
  conversationsActions.fetch({
    url: `/conversations/${id}`,
    query,
    schema: { conversation: ConversationSchema },
  })

export const updateConversation = (id, query) =>
  conversationsActions.update({
    url: `/conversations/${id}`,
    query,
    schema: { conversation: ConversationSchema },
  })

export const conversationsCustomHandlers = {
  [conversationsActions.actionTypes.FETCHED]: mergeMetadata('conversations'),
}
