import moment from 'moment'
import { paramsToMomentUtc } from './date'

export function isValidView(params) {
  const yearIsValid = !Number.isNaN(parseInt(params.year))
  const monthIsValid = typeof params.month === 'undefined' || !Number.isNaN(parseInt(params.month))
  const dayIsValid = typeof params.day === 'undefined' || !Number.isNaN(parseInt(params.day))
  return yearIsValid && monthIsValid && dayIsValid
}

export const isDayView = (params) => !!params.day
export const isMonthView = (params) => !!params.month && !params.day

export function hasDatasForMonth(schedules, momentDate) {
  const allStrDays = new Array(momentDate.daysInMonth()).fill().map((v, index) =>
    momentDate
      .clone()
      .date(index + 1)
      .format('YYYY-MM-DD'),
  )

  for (const strDay of allStrDays) if (!schedules.get(strDay)) return false
  return true
}

export function hasDatasForView(params, schedules) {
  if (!isValidView(params)) return

  if (isMonthView(params)) return hasDatasForMonth(schedules, paramsToMomentUtc(params))
}

/**
 * Check whether the second month schedules needs to be fetched.
 */
export function needsToFetchSecondMonth() {
  return !moment.utc().add(14, 'days').isSame(moment.utc(), 'month')
}
