import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching, getFetchError, getObjId } from './_utils'
import { getFile, getLanguage, getEvents } from 'redux/selectors'

export const getUsers = getEntities('users')
export const getUser = getEntity('users')

export const getUserAvatar = createSelector(
  getUser,
  (state) => state,
  (user, state) => getFile(state, getObjId(user, 'avatar')),
)
export const getUserLanguage = createSelector(
  getUser,
  (state) => state,
  (user, state) => getLanguage(state, getObjId(user, 'language')),
)

export const getUserLanguages = createSelector(
  getUser,
  (state) => state,
  (user, state) => user.languages.map((id) => getLanguage(state, id)),
)
export const getUserEvents = createSelector(
  getEvents,
  (_, userId) => parseInt(userId, 10),
  (events, userId) => events.filter((event) => event.user_id === userId).toList(),
)

export const fetchingUser = (state, id) => isFetching(`/users/${id}`)(state)

export const getFetchUserError = (state, id) => getFetchError(`/users/${id}`)(state)
