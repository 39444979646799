import settings from 'settings'
import axios from 'axios'

class Api {
  constructor({ baseURL, interceptors = {}, headers = {}, ...config }) {
    this.config = { headers, ...config }
    this.instance = axios.create({ baseURL })
    if (interceptors.request) {
      this.instance.interceptors.request.use(interceptors.request.changeConfig, interceptors.request.onConfigError)
    }
    if (interceptors.response) {
      this.instance.interceptors.response.use(interceptors.response.onResponse, interceptors.response.onError)
    }
  }

  getHeader = (key = '') => {
    return typeof key === 'string' ? this.config.headers[key.toLocaleLowerCase()] : undefined
  }

  setHeader = (key = '', value) => {
    if (typeof key === 'string') this.config.headers[key.toLocaleLowerCase()] = value
  }

  removeHeader = (key = '') => {
    if (typeof key === 'string') delete this.config.headers[key.toLocaleLowerCase()]
  }

  get = (url, config = {}) => this.instance.get(url, { ...this.config, ...config })

  put = (url, payload, config = {}) => this.instance.put(url, payload, { ...this.config, ...config })

  post = (url, payload, config = {}) => this.instance.post(url, payload, { ...this.config, ...config })

  patch = (url, payload, config = {}) => this.instance.patch(url, payload, { ...this.config, ...config })

  delete = (url, config) => this.instance.delete(url, { ...this.config, ...config })
}

function logRequest(req) {
  console.log(req.url, req.params || '', req.body || '') // eslint-disable-line no-console
  return req
}

export const screenApi = new Api({
  baseURL: settings.apiUrl,
  interceptors: {
    request: process.env.NODE_ENV !== 'production' && {
      changeConfig: logRequest,
    },
    response: {
      onResponse: undefined,
      // Make sure the error given in the Promise.catch is structure like:
      // error = {headers, data, status, statusText, ...}
      onError: (error) => {
        if (error.response) error = error.response
        if (process.env.NODE_ENV !== 'production') {
          /* eslint-disable no-console */
          if (error.status) console.error('api error', error.status, error.statusText, error.config.url)
          else console.error('unexpected error', error)
          /* eslint-enable no-console */
        }
        return Promise.reject(error)
      },
    },
  },
  headers: {
    Accept: `application/vnd.vizeat.com::${settings.screenApiVersion}+json`,
  },
})

export const cloudFlareWorkersApi = new Api({
  baseURL: settings.cloudFlareWorkersApi,
  interceptors: {
    request: {
      changeConfig: process.env.NODE_ENV !== 'production' && logRequest,
    },
  },
})
