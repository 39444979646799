import { schema } from 'normalizr'
import { Record, Map, List } from 'immutable'
import { FileSchema } from './files'
import { EventSchema } from './events'

export const PlaylistSchema = new schema.Entity('playlists', {
  cover: FileSchema,
  events: [EventSchema],
})

export const PlaylistRecord = Record({
  id: undefined,
  type: '',
  order: undefined,
  title: '',
  body: '',
  cover_id: undefined,
  cover: undefined,
  key_words: new Map({
    locality: '',
    country: '',
  }),

  search: new Map({
    // NOTE: used for playlists
    event_ids: new List(),
  }),
  search_params: new Map({
    // NOTE: used for destinations
    url: '',
    default_nb_results: undefined,
    locality: '', // '[Locality],[Country]'
  }),
  events: new List(),
})

export const emptyPlaylist = new PlaylistRecord()
