import { schema } from 'normalizr'
import { Record } from 'immutable'
import { EventSchema } from './events'
import { UserSchema } from './users'
import { PlaceSchema } from './places'

export const RequestSchema = new schema.Entity('requests', {
  event: EventSchema,
  user: UserSchema,
  place: PlaceSchema,
  // booking schema is linked in ./_utils.js due to circular dependencies
})

export const RequestRecord = Record({
  id: undefined,

  additional_info: undefined,
  begins_at: undefined,
  bookings: undefined,
  conversation_id: undefined,
  conversation: undefined,
  date: undefined,
  ends_at: undefined,
  event_id: undefined,
  event: undefined,
  occasion: undefined,
  origination: undefined,
  user_id: undefined,
  user: undefined,
  seats: undefined,

  approved_at: undefined,
  canceled_at: undefined,
  expired_at: undefined,
  privatized_at: undefined,
  will_expire_at: undefined,

  canceled_by: undefined,

  created_at: undefined,
  updated_at: undefined,

  invite_token: undefined,
})

export const emptyRequest = new RequestRecord()
