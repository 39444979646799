import { schema } from 'normalizr'
import { Record } from 'immutable'

export const FileSchema = new schema.Entity('files')

export const FileRecord = Record({
  id: undefined,
  account_id: undefined,
  uploadcare_id: undefined,
  path: '',
  attachable_id: undefined,
  attachable_type: '',
  category: undefined,
  order: undefined,
  show: undefined,
})

export const emptyFile = new FileRecord()
