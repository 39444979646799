import moment from 'moment'
import { Map, List, Set } from 'immutable'
import { filterBookings } from 'helpers/booking'

// filtering helpers
export function filterSchedulesByBookingField(schedules, field, status, eventId) {
  if (!eventId || eventId === 'all') {
    return schedules.filter((schedule) => !!schedule.bookings.find((b) => b[field] === status))
  }
  return schedules.filter(
    (schedule) => !!schedule.bookings.find((b) => b[field] === status && b.event_id === parseInt(eventId)),
  )
}

export function filterRequests(schedules, eventId) {
  if (!eventId || eventId === 'all') {
    return schedules.filter((schedule) => schedule.requests.size > 0)
  }
  return schedules.filter((schedule) => !!schedule.requests.find((r) => r.event_id === parseInt(eventId)))
}

export function getClosedDates(schedules, events, eventId = 'all') {
  if (!schedules.size || !events.size) return []
  let predicate
  if (eventId !== 'all') predicate = (schedule) => schedule.closed_event_ids.includes(parseInt(eventId))
  else {
    predicate = (schedule) => {
      const closedEvents = events.filter((event) => schedule.closed_event_ids.includes(event.id))
      return !!schedule.closed_event_ids.size && closedEvents.size === events.size
    }
  }
  return schedulesToMomentUtcArray(schedules.filter(predicate))
}
export function getHalfClosedDates(schedules, events, eventId = 'all') {
  if (eventId !== 'all' || !schedules.size || !events.size) return []
  const halfClosedSchedules = schedules.filter((schedule) => {
    if (!schedule.closed_event_ids.size) return false
    const closedEvents = events.filter(({ id }) => schedule.closed_event_ids.includes(id))
    return closedEvents.size < events.size // if less closed events than events provided, not all are closed
  })
  return schedulesToMomentUtcArray(halfClosedSchedules)
}
export function getRequestableDates(schedules, events, eventId = 'all') {
  if (!schedules.size || !events.size) return []
  let predicate
  if (eventId !== 'all') predicate = (schedule) => schedule.requestable_event_ids.includes(parseInt(eventId))
  else {
    predicate = (schedule) => {
      const requestableEvents = events.filter((event) => schedule.requestable_event_ids.includes(event.id))
      return !!schedule.requestable_event_ids.size && requestableEvents.size === events.size
    }
  }
  return schedulesToMomentUtcArray(schedules.filter(predicate))
}
export function getOpenedDates(schedules, events, eventId = 'all') {
  if (!schedules.size || !events.size) return []
  let predicate
  if (eventId !== 'all') predicate = (schedule) => schedule.opened_event_ids.includes(parseInt(eventId))
  else {
    predicate = (schedule) => {
      const requestableEvents = events.filter((event) => schedule.opened_event_ids.includes(event.id))
      return !!schedule.opened_event_ids.size && !!requestableEvents.size
    }
  }
  return schedulesToMomentUtcArray(schedules.filter(predicate))
}

export function getInstantBookingDates(schedules, events, eventId = 'all') {
  if (!schedules.size || !events.size) return []
  const predicate =
    eventId === 'all'
      ? (schedule) =>
          !!schedule.instant_booking_event_ids.size &&
          events.filter((event) => schedule.instant_booking_event_ids.includes(event.id)).size
      : (schedule) => schedule.instant_booking_event_ids.includes(parseInt(eventId))
  return schedulesToMomentUtcArray(schedules.filter(predicate))
}

export function filterEvents({ events = new List(), schedule, idNotIn = '', filterBooked = false }) {
  if (!schedule) return new Map()
  const bookedEvents = !filterBooked ? new Set() : schedule.bookings.map((b) => b.event_id || b.event.id).toSet()
  return events.filter((e) => !schedule[idNotIn].includes(e.id) && !bookedEvents.has(e.id))
}

export function getSeatsBookedForStatus({ bookings, status, groupStatus, event }) {
  return filterBookings({ bookings, status, groupStatus, event }).reduce((seats, b) => seats + (b.seats || 0), 0)
}

export function getFreeSeatsForStatus({ bookings, status, groupStatus, event }) {
  return filterBookings({ bookings, status, groupStatus, event }).reduce(
    (seats, b) => seats + (b.host_free_seats || 0),
    0,
  )
}

export function computeTotalPrice({ bookings, status, groupStatus, unitPrice }) {
  const confirmedSeats = getSeatsBookedForStatus({ bookings, status, groupStatus })
  const freeSeats = getFreeSeatsForStatus({ bookings, status, groupStatus })

  return unitPrice * ((confirmedSeats || 0) - (freeSeats || 0))
}

export function getSeatsRequested({ requests, event }) {
  const validRequests = requests.filter((request) => {
    if (event && (request.event_id || request.event) !== event.id) return false
    return true
  })
  return validRequests.reduce((seats, r) => seats + (r.seats || 0), 0)
}

/**
 * @param {object} schedule
 * @param {object} event
 * @returns {number}
 */
export const getSeatsLeft = (schedule = {}, event = {}) => {
  if (!event.max_seats) return 0
  return event.max_seats - getSeatsBookedForStatus({ bookings: schedule.bookings, groupStatus: 'successful', event })
}

/**
 * @param {object} schedule
 * @param {object} event
 * @returns {number}
 */
export function getSeatsBooked(schedule = {}, event = {}) {
  if (!event.max_seats) return 0
  return getSeatsBookedForStatus({ bookings: schedule.bookings, groupStatus: 'successful', event })
}

/**
 * @param  {Immutable.Map|Immutable.List} schedules
 * @return {ArrayOf(moment)}
 */
export function schedulesToMomentUtcArray(schedules) {
  return (schedules || new List()).map(({ date }) => moment.utc(date)).toArray()
}

export function sortByTime(a, b) {
  return moment(a.begins_at, 'HH:mm') - moment(b.begins_at, 'HH:mm')
}
