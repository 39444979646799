import { createSelector } from 'reselect'
import { getEntities, getEntity, getFetchError, isFetching } from './_utils'
import { emptyCurrency } from 'redux/schemas'

export const getCurrencies = getEntities('currencies')
export const getCurrency = getEntity('currencies')

export const fetchingCurrencies = isFetching('/currencies')

export const getCurrencyByIso3 = createSelector(
  getCurrencies,
  (_, iso3) => iso3,
  (currencies, iso3) => currencies.find((currency) => currency.iso_3 === iso3, null, emptyCurrency),
)

export const getCurrenciesError = (state) => getFetchError(`/currencies`)(state)
