import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching } from './_utils'
import { List } from 'immutable'
import { getBankAccount } from 'redux/selectors'

// getters
export const getStripeAccounts = getEntities('stripeAccounts')
export const getStripeAccount = (state) => getEntity('stripeAccounts')(state, { id: 1 })

const getStripeAccountFieldsNeeded = createSelector(
  getStripeAccount,
  (stripeAccount) =>
    stripeAccount.requirements
      .get('currently_due', new List())
      .merge(stripeAccount.requirements.get('eventually_due', new List()))
      .merge(stripeAccount.requirements.get('past_due', new List()))
      .filter((item) => item !== 'external_account'), // only care for ID fields
)

export const getStripeAccountNeedsFields = createSelector(
  getStripeAccountFieldsNeeded,
  (fieldsNeeded) => fieldsNeeded.size > 0,
)

export const getStripeAccountBankAccounts = createSelector(
  getStripeAccount,
  (state) => state,
  (stripeAccount, state) => stripeAccount.bankAccounts.map((id) => getBankAccount(state, id)),
)

export const getStripeAccountAddress = createSelector(getStripeAccount, (stripeAccount) => stripeAccount.address || {})

export const getStripeAccountOnboardingComplete = createSelector(
  getStripeAccount,
  (stripeAccount) => stripeAccount.onboardingComplete,
)

// notifiers
export const fetchingStripeAccount = isFetching('/users/profile/account/stripe')
