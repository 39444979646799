import { screenApi } from 'apis'
import { ActionsCreator, mergeMetadata } from '../lib'
import { ReviewSchema } from 'redux/schemas'

export const reviewsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'reviews' })

export const fetchReviews = (query) =>
  reviewsActions.fetch({
    url: '/reviews',
    query,
    schema: { reviews: [ReviewSchema] },
  })

export const createReview = (bookingId, payload, query) =>
  reviewsActions.create({
    url: `/bookings/${bookingId}/reviews`,
    payload,
    query,
    schema: { review: ReviewSchema },
  })

export const reviewsCustomHandlers = {
  [reviewsActions.actionTypes.FETCHED]: mergeMetadata('reviews'),
}
