import settings from 'settings'
import { vizeatUrls } from '@vizeat/helpers'
import { omit, isEqual } from 'lodash'

export const urlFactory = vizeatUrls.vizeatUrlsFactory({
  config: { url: { host: settings.domainName }, env: process.env.NODE_ENV },
})

export function isCurrentPath(path, location) {
  const { search, pathname, query } = location

  if (typeof path === 'object') {
    const toOmit = ['page', 'c']
    return path.pathname === pathname && isEqual(omit(query, toOmit), omit(path.query, toOmit))
  }
  if (typeof path === 'string') {
    // Avoid currency in querystring
    // If query contains page data, consider only pathname
    // Else consider whole path, search included
    const searchString = search
      .replace(/[?&]c=[A-Z]{3}/, '')
      .replace(/[?&]page=[0-9]+/, '')
      .replace(/^&/, '?')
    return path === `${pathname}${searchString}`
  }
}
