import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import {
  AmenitySchema,
  AlcoholSchema,
  CriterionSchema,
  CurrencySchema,
  DietSchema,
  FoodSchema,
  LanguageSchema,
} from 'redux/schemas'

export const amenitiesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'amenities' })
export const alcoholsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'alcohols' })
export const criteriaActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'criteria' })
export const currenciesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'currencies' })
export const dietsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'diets' })
export const foodsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'foods' })
export const languagesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'languages' })

export const fetchAmenities = (query) =>
  amenitiesActions.fetch({
    url: '/amenities',
    query,
    schema: { amenities: [AmenitySchema] },
  })

export const fetchAlcohols = (query) =>
  alcoholsActions.fetch({
    url: '/alcohols',
    query,
    schema: { alcohols: [AlcoholSchema] },
  })

export const fetchCriteria = (query) =>
  criteriaActions.fetch({
    url: '/criteria',
    query,
    schema: { criteria: [CriterionSchema] },
  })

export const fetchCurrencies = (query) =>
  currenciesActions.fetch({
    url: '/currencies',
    query,
    schema: { currencies: [CurrencySchema] },
  })

export const fetchDiets = (query) =>
  dietsActions.fetch({
    url: '/diets',
    query,
    schema: { diets: [DietSchema] },
  })

export const fetchFoods = (query) =>
  foodsActions.fetch({
    url: '/foods',
    query,
    schema: { foods: [FoodSchema] },
  })

export const fetchLanguages = (query) =>
  languagesActions.fetch({
    url: '/languages',
    query,
    schema: { languages: [LanguageSchema] },
  })
