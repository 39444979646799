import { getEntities, getEntity, isCreating, getCreateError } from './_utils'

export const getReplies = getEntities('replies')
export const getReply = getEntity('replies')

// notifiers
export const creatingReply = (state, conversationId) => isCreating(`/conversations/${conversationId}/replies`)(state)

// errors
export const getCreateReplyError = (state, conversationId) =>
  getCreateError(`/conversations/${conversationId}/replies`)(state)
