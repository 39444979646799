import { screenApi } from 'apis'
import { union } from 'lodash'
import { ActionsCreator, clearHandler } from '../lib'
import { ScheduleSchema, BookingSchema, EventSchema, NoteSchema, RequestSchema } from 'redux/schemas'
import { gtmHostCanceledBooking } from 'redux/actions'

const planningActions = {
  CANCELLING_SCHEDULED_EVENT: 'CANCELLING_SCHEDULED_EVENT',
  CANCEL_SCHEDULED_EVENT_FAILURE: 'CANCEL_SCHEDULED_EVENT_FAILURE',
  CANCEL_SCHEDULED_EVENT_SUCCESS: 'CANCEL_SCHEDULED_EVENT_SUCCESS',
  CLEAR_SCHEDULES: 'CLEAR_SCHEDULES',
  CLEAR_OVERRIDES: 'CLEAR_OVERRIDES',
}

const defaultSchema = {
  planning: {
    schedule: [ScheduleSchema],
    bookings: [BookingSchema],
    requests: [RequestSchema],
    events: [EventSchema],
    notes: [NoteSchema],
  },
}

export const schedulesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'schedules', defaultSchema })

export const fetchSchedules = (mDateStart, mDateEnd) => {
  let url = `/planning/${mDateStart.format('YYYY-MM')}`
  if (mDateEnd) url += `/${mDateEnd.format('YYYY-MM')}`
  return schedulesActions.fetch({ url })
}

export const fetchHostMonthSchedules = (hostId, mDateStart, mDateEnd = undefined) => {
  const url = mDateEnd
    ? `/users/${hostId}/planning/${mDateStart.format('YYYY-MM')}/${mDateEnd.format('YYYY-MM')}`
    : `/users/${hostId}/planning/${mDateStart.format('YYYY-MM')}`
  return schedulesActions.fetch({ url })
}

export const fetchHostDaySchedule = (hostId, mDate) =>
  schedulesActions.fetch({ url: `/users/${hostId}/planning/${mDate.format('YYYY-MM-DD')}` })

export const fetchEventNextSchedule = (eventId) => schedulesActions.fetch({ url: `/events/${eventId}/planning/next` })

export const fetchEventMonthSchedules =
  (eventId, mDateStart, mDateEnd = undefined) =>
  (dispatch, getState) => {
    const url = mDateEnd
      ? `/events/${eventId}/planning/${mDateStart.format('YYYY-MM')}/${mDateEnd.format('YYYY-MM')}`
      : `/events/${eventId}/planning/${mDateStart.format('YYYY-MM')}`
    return dispatch(schedulesActions.fetch({ url }))
  }

export const fetchEventDaySchedule = (eventId, mDate) =>
  schedulesActions.fetch({ url: `/events/${eventId}/planning/${mDate.format('YYYY-MM-DD')}` })

export const updateSchedules = (schedule) => (dispatch) => {
  dispatch({ type: planningActions.CLEAR_OVERRIDES, payload: { schedule } })
  return dispatch(schedulesActions.update({ url: '/planning', payload: { schedule } }))
}

export const clearSchedules = () => (dispatch, getState) => dispatch({ type: planningActions.CLEAR_SCHEDULES })

export const cancelScheduledEvent =
  ({ bookings, eventId, date }) =>
  (dispatch) => {
    dispatch({ type: planningActions.CANCELLING_SCHEDULED_EVENT })
    return Promise.all(
      bookings
        .toArray()
        .map((booking) => screenApi.delete(`/bookings/${booking.id}`).then(() => dispatch(gtmHostCanceledBooking()))),
    )
      .then(() => dispatch(updateSchedules([{ date, close_event_ids: [eventId] }])))
      .then(() => dispatch({ type: planningActions.CANCEL_SCHEDULED_EVENT_SUCCESS }))
      .catch(() => dispatch({ type: planningActions.CANCEL_SCHEDULED_EVENT_FAILURE }))
  }

export const schedulesCustomHandlers = {
  [planningActions.CANCELLING_SCHEDULED_EVENT]: (state) =>
    state.mergeIn(['metadata', 'schedules'], { isLoading: true }),
  [planningActions.CANCEL_SCHEDULED_EVENT_SUCCESS]: (state) =>
    state.mergeIn(['metadata', 'schedules'], { isLoading: false }),
  [planningActions.CANCEL_SCHEDULED_EVENT_FAILURE]: (state) =>
    state.mergeIn(['metadata', 'schedules'], { isLoading: false }),
  [planningActions.CLEAR_SCHEDULES]: clearHandler('schedules'),
  [planningActions.CLEAR_OVERRIDES]: (state, action) => {
    const { schedule } = action.payload
    const overridesToRemove = schedule
      .map((date) =>
        union(date.open_event_ids, date.close_event_ids, date.requestable_event_ids).map(
          (eventId) => `${eventId}-${date.date}`,
        ),
      )
      .flat()
    const remainingOverrides = state
      .getIn(['entities', 'overrides'])
      .filterNot((overide, key) => overridesToRemove.includes(key))
    return state.setIn(['entities', 'overrides'], remainingOverrides)
  },
}
