import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching } from './_utils'
import { getCouponBooking, getCurrency } from 'redux/selectors'

export const getCampaigns = getEntities('campaigns')
export const getCampaign = getEntity('campaigns')

export const getCampaignsNonReferral = createSelector(getCampaigns, (campaigns) =>
  campaigns.filter((campaign) => campaign.category !== 'REFERRAL'),
)

export const getCampaignReferral = createSelector(getCampaigns, (campaigns) =>
  campaigns.find((campaign) => campaign.category === 'REFERRAL'),
)

export const getCampaignReferralCouponsBooking = createSelector(
  getCampaignReferral,
  (state) => state,
  (campaign, state) => {
    return campaign ? campaign.coupons.map((couponId) => getCouponBooking(state, couponId)) : null
  },
)

export const getCampaignCurrency = createSelector(
  getCampaign,
  (state) => state,
  (campaign, state) => getCurrency(state, campaign.currency),
)

export const fetchingCampaignReferral = isFetching('/campaigns/referral')
