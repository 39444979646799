import { createSelector } from 'reselect'
import { getEntities, getEntity, isCreating, getCreateError } from './_utils'
import { getCurrentUser } from 'redux/selectors'

export const getFiles = getEntities('files')
export const getFile = getEntity('files')

export const getUserFiles = createSelector(
  getFiles,
  (state) => getCurrentUser(state),
  (files, user) => files.filter((file) => file.attachable_id === user.id),
)

export const creatingEventFiles = (state, id) => isCreating(`/events/${id}/files`)(state)
export const creatingUserFiles = isCreating('/files')
export const creatingReviewFiles = (state, id) => isCreating(`/reviews/${id}/files`)(state)
export const getCreateReviewFilesError = (state, id) => getCreateError(`/reviews/${id}/files`)(state)
