import { createSelector } from 'reselect'
import { getEntities, getEntity, getMetadata, isFetching, getEntityOrder, getObjId } from './_utils'
import { getCurrency } from 'redux/selectors'
import moment from 'moment'

export const fetchingPayouts = isFetching('/payouts')
export const fetchingPayout = (state, id) => isFetching(`/payouts/${id}`)(state)

export const getPayouts = getEntities('payouts')
export const getPayout = getEntity('payouts')

export const getSortedPayouts = createSelector(getPayouts, (payouts) =>
  payouts.toList().sort((a, b) => moment(b.sent_at).diff(a.sent_at)),
)

export const getPayoutsList = createSelector(
  getEntityOrder('payouts'),
  (state) => state,
  (ids, state) => ids.map((id) => getPayout(state, id)),
)

export const getPayoutsMetadata = getMetadata('payouts')

export const getPayoutCurrency = createSelector(
  getPayout,
  (state) => state,
  (payout, state) => getCurrency(state, getObjId(payout, 'currency')),
)
