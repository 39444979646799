import { cloudFlareWorkersApi } from 'apis'
import { ActionsCreator } from '../lib'
import { TripAdvisorUserSchema } from 'redux/schemas'
import { getAppLocale } from 'redux/selectors'

export const tripAdvisorUsersActions = new ActionsCreator({
  Api: cloudFlareWorkersApi,
  actionTypesPrefix: 'tripAdvisorUsers',
})

export const statefulFetchTripAdvisorUser = (id) => (dispatch, getState) => {
  const query = { id, language: getAppLocale(getState()) }
  return dispatch(fetchTripAdvisorUser(query))
}

export const fetchTripAdvisorUser = (query) =>
  tripAdvisorUsersActions.fetch({
    url: '/tar/location',
    query,
    schema: TripAdvisorUserSchema,
  })
