import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'helpers/proptypes'
import { Modal } from '@vizeat/components/es6/components/Modal'

const initalState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
}

const GlobalModalContext = createContext(initalState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

export const GlobalModalProvider = ({ children }) => {
  const [store, setStore] = useState({ modalProps: { show: false }, modalContent: null })
  const { modalProps, modalContent } = store

  const showModal = (modalProps, modalContent) => {
    setStore({
      ...store,
      modalProps,
      modalContent,
    })
  }

  const hideModal = () => {
    setStore({
      ...store,
      modalProps: {},
      modalContent: null,
    })
  }

  const renderComponent = () => {
    if (!modalProps.show) {
      return null
    }
    return (
      <Modal onHide={hideModal} id='global-modal' {...modalProps}>
        {modalContent}
      </Modal>
    )
  }

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  )
}

GlobalModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
