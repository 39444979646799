import { createSelector } from 'reselect'
import moment from 'moment'
import { List } from 'immutable'
import { emptySchedule } from 'redux/schemas'

import {
  filterSchedulesByBookingField,
  filterRequests,
  getClosedDates,
  getHalfClosedDates,
  getRequestableDates,
  getOpenedDates,
  getInstantBookingDates,
} from 'helpers/planning'

import {
  getEntitiesState,
  getEntities,
  getMetadata,
  getUpdateError,
  isFetching,
  isFetchingRegex,
  isUpdating,
} from './_utils'

import {
  getEvent,
  getEventWithOverrides,
  getBooking,
  getRequest,
  getCurrentUserPublishedEvents,
  getNote,
  inviteScheduleIsBookable,
} from 'redux/selectors'

export const getSchedules = getEntities('schedules')
export const getArgs = (state, date, id) => ({ state, id, mDate: moment.utc(date) })

export const getSchedulesFromToday = createSelector(
  getSchedules,
  (state) => state,
  (schedules, state) => {
    const today = moment.utc()
    return schedules
      .filter((_schedule, date) => moment.utc(date).isSameOrAfter(today, 'days'))
      .map((_schedule, date) => getScheduleForDate(state, date))
      .sort((a, b) => moment(a.date).diff(b.date))
  },
)

export const getSchedulesForMonth = createSelector(
  getSchedules,
  (state, mDate, id) => ({ state, mDate, id }),
  (schedules, { state, mDate, id }) =>
    schedules
      .filter((_schedule, date) => moment.utc(date).isSame(mDate, 'month'))
      .map((_schedule, date) => getScheduleForDate(state, date, id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getSchedulesForMonthFromToday = createSelector(getSchedulesForMonth, (schedules) => {
  const today = moment.utc()
  return schedules.filter((_schedule, date) => moment.utc(date).isSameOrAfter(today, 'days'))
})

export const getBookedSchedules = createSelector(getSchedulesForMonth, (schedules) =>
  schedules.filter((schedule) => schedule.booking_ids.size > 0),
)

export const getSchedule = createSelector(getSchedules, getArgs, (schedules, { mDate }) => {
  if (!mDate || !moment.isMoment(mDate) || !mDate.isValid()) return emptySchedule
  return schedules.get(mDate.format('YYYY-MM-DD'), emptySchedule)
})

function mapEvents(state, eventIds = new List(), eventId = null) {
  return eventIds
    .filter((id) => eventId === null || eventId === id)
    .map((id) => getEvent(state, id))
    .toMap()
    .mapKeys((k, event) => event.id)
}

export const getScheduleForDate = createSelector(getSchedule, getArgs, (schedule, { state, id }) =>
  schedule
    .setIn(['closed_events'], mapEvents(state, schedule.closed_event_ids, id))
    .setIn(['opened_events'], mapEvents(state, schedule.opened_event_ids, id))
    .setIn(['bookable_events'], mapEvents(state, schedule.bookable_event_ids, id))
    .setIn(['instant_booking_events'], mapEvents(state, schedule.instant_booking_event_ids, id))
    .setIn(['requestable_events'], mapEvents(state, schedule.requestable_event_ids, id))
    .setIn(
      ['bookings'],
      schedule.booking_ids
        .map((bookingId) => getBooking(state, bookingId))
        .filter((booking) => !!booking.id)
        .filter(({ event_id: eventId }) => !id || id === 'all' || id === eventId)
        .toMap()
        .mapKeys((k, booking) => booking.id),
    )
    .setIn(['note'], schedule.note_id ? getNote(state, schedule.note_id) : undefined)
    .setIn(
      ['requests'],
      schedule.request_ids
        .map((requestId) => getRequest(state, requestId))
        .filter(({ event_id: eventId }) => !id || id === 'all' || id === eventId)
        .toMap()
        .mapKeys((k, request) => request.id),
    ),
)

export const hasSchedulesMonthBeenFetched = createSelector(
  getSchedulesForMonth,
  (state) => state,
  (schedules, state) =>
    !!schedules.find((s) => !!(s.closed_events.size || s.opened_events.size || s.bookable_events.size)),
)

export const getSchedulesForEvent = createSelector(
  getSchedules,
  (state, id) => ({ state, id }),
  (schedules, { state, id }) =>
    schedules
      .map((value, idDate) => moment.utc(idDate))
      .map((date) => getScheduleForDate(state, date, id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

// Selectors for a month of schedules, without an event
export const getSchedulesDisabledDays = createSelector(getSchedulesForMonth, (schedules) => {
  const disabledDates = (acc, el) => {
    if (moment(el.date).isBefore(moment(), 'day')) return [...acc, moment(el.date)]
    return el.bookable_event_ids.size > 0 || el.requestable_event_ids.size > 0 ? acc : [...acc, moment(el.date)]
  }
  return schedules.reduce(disabledDates, [])
})

// Selectors for month of schedules for a given event
export const getEventSchedulesOpenedBookableDays = createSelector(
  getSchedulesForMonth,
  getArgs,
  (schedules, { id }) => {
    const openedDates = (acc, el) => {
      if (moment(el.date).isBefore(moment(), 'day')) return acc
      return el.opened_event_ids.includes(id) && el.bookable_event_ids.includes(id) ? [...acc, moment(el.date)] : acc
    }
    return schedules.reduce(openedDates, [])
  },
)

export const getEventSchedulesCrossSellDays = createSelector(getSchedulesForMonth, getArgs, (schedules, { id }) => {
  const crossSellDays = (acc, el) => {
    if (moment(el.date).isBefore(moment(), 'day')) return acc
    const isEventOpen = el.opened_event_ids.includes(id)
    const eventAlternatives = el.bookable_event_ids.filter((value) => el.opened_event_ids.includes(value)).size
    return !isEventOpen && eventAlternatives > 0 ? [...acc, moment(el.date)] : acc
  }
  return schedules.reduce(crossSellDays, [])
})

// Selectors for a specific date schedule
export const scheduleIsBookable = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('bookable_event_ids').includes(id),
)

export const scheduleIsOpened = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('opened_event_ids').includes(id),
)

export const scheduleIsBookableAndOpened = createSelector(
  scheduleIsBookable,
  scheduleIsOpened,
  (bookable, opened) => bookable && opened,
)

export const scheduleIsRequestable = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('requestable_event_ids').includes(id),
)

export const scheduleIsInstantBookable = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('instant_booking_event_ids').includes(id),
)

// Selectors for a month of schedules with an event
export const getBookableSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => schedule.bookable_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getNonBookableSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => !schedule.bookable_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getOpenedSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules.filter((schedule) => schedule.opened_event_ids.includes(id)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getNonOpenedSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules.filter((schedule) => !schedule.opened_event_ids.includes(id)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getInstantBookingSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => schedule.instant_booking_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getOpenBookableSchedules = createSelector(getBookableSchedules, getOpenedSchedules, (bookable, opened) =>
  bookable.filter((_, key) => opened.has(key)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getInstantOpenBookableSchedules = createSelector(
  getOpenBookableSchedules,
  getInstantBookingSchedules,
  (openBookable, instant) =>
    openBookable.filter((_, key) => instant.has(key)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getNonOpenBookableSchedules = createSelector(
  getSchedulesForEvent,
  getOpenBookableSchedules,
  (schedules, openedBookable) =>
    schedules.filter((_, key) => !openedBookable.has(key)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getRequestableSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => schedule.requestable_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getSoldOutSchedules = createSelector(
  getOpenedSchedules,
  (_, id) => id,
  (state) => state,
  (opened, id, state) => {
    return opened
      .filter((schedule) => getScheduleEventBookingSeatsLeft(state, moment.utc(schedule.date), id) === 0)
      .sort((a, b) => moment(a.date).diff(b.date))
  },
)

export const getClosedSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules.filter((schedule) => schedule.closed_event_ids.includes(id)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getScheduleBookings = createSelector(getScheduleForDate, (schedule) => schedule.bookings)

export const getSchedulesNextOpenDates = createSelector(
  getSchedules,
  (_, id) => id,
  (schedules, id) => {
    const bookableDates = schedules
      .filter((s) => s.bookable_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date))

    const openDates = bookableDates.filter((s) => s.opened_event_ids.includes(id)).slice(0, 5)

    return openDates.size > 0 ? openDates : bookableDates.slice(0, 5)
  },
)

export const getSchedulesFirstOpenDate = createSelector(getOpenBookableSchedules, (schedules) => {
  const schedule = schedules.first() || emptySchedule
  return schedule.date ? moment.utc(schedule.date) : undefined
})

const getScheduleBookingSeatsLeft = createSelector(
  getScheduleBookings,
  (state, mDate, id) => getEventWithOverrides(state, id, mDate.format('YYYY-MM-DD')),
  getArgs,
  (bookings, event, { mDate, id }) => {
    const result = bookings.reduce(
      (seats, b) => (b.groupStatus === 'successful' && b.event_id === id ? seats - b.seats : seats),
      event.max_seats,
    )
    return result > 0 ? result : 0
  },
)

export const getScheduleEventBookingSeatsLeft = (state, mDate, id) =>
  !mDate || !mDate.isValid() ? getEvent(state, id).max_seats || 0 : getScheduleBookingSeatsLeft(state, mDate, id)

const getScheduleBookingSeatsBooked = createSelector(
  getScheduleBookings,
  (state, mDate, id) => getEvent(state, id),
  getArgs,
  (bookings, event, { mDate, id }) =>
    bookings.reduce((seats, b) => (b.groupStatus === 'successful' && b.event_id === id ? seats + b.seats : seats), 0),
)

export const getScheduleEventBookingSeatsBooked = (state, mDate, id) =>
  !mDate || !mDate.isValid() ? 0 : getScheduleBookingSeatsBooked(state, mDate, id)

export const getScheduleDateEvents = createSelector(getScheduleForDate, getArgs, (schedule, { state, mDate }) => {
  const ids = schedule.opened_event_ids.concat(schedule.requestable_event_ids)
  return ids.map((eventId) => getEventWithOverrides(state, eventId, mDate.format('YYYY-MM-DD')))
})

export const scheduleIsSoldOut = createSelector(
  scheduleIsOpened,
  getScheduleEventBookingSeatsLeft,
  (opened, seatsLeft) => opened && seatsLeft === 0,
)

// Args: id, date
export const isEventInstantlyBookable = createSelector(
  getEventWithOverrides,
  inviteScheduleIsBookable,
  (event, inviteScheduleIsBookable) => event.instant_booking || event.privatized_by || inviteScheduleIsBookable,
)

// modifiers
const getSchedulesByBookingGroupStatus = createSelector(
  getSchedulesForMonth,
  (state, mDate, id, status) => ({ id, status }),
  (schedules, { id, status }) => filterSchedulesByBookingField(schedules, 'groupStatus', status, id),
)

export const getSchedulesWithPendingBookings = (state, mDate, id) =>
  getSchedulesByBookingGroupStatus(state, mDate, id, 'pending')

export const getSchedulesWithConfirmedBookings = (state, mDate, id) =>
  getSchedulesByBookingGroupStatus(state, mDate, id, 'successful')

const getSchedulesByBookingStatus = createSelector(
  getSchedulesForMonth,
  (state, mDate, id, status) => ({ id, status }),
  (schedules, { id, status }) => filterSchedulesByBookingField(schedules, 'status', status, id),
)

export const getSchedulesWithTentativeBookings = (state, mDate, id) =>
  getSchedulesByBookingStatus(state, mDate, id, 'tentative')

export const getSchedulesWithRequests = createSelector(
  getSchedulesForMonth,
  (state, mDate, id) => id,
  (schedules, id) => filterRequests(schedules, id),
)

// selectors for the current user's events
export const getSchedulesWithDateClosed = createSelector(
  getSchedulesForMonth,
  getCurrentUserPublishedEvents,
  getArgs,
  (schedules, events, { id }) => getClosedDates(schedules, events, id),
)

export const getSchedulesWithDateHalfClosed = createSelector(
  getSchedulesForMonth,
  getCurrentUserPublishedEvents,
  getArgs,
  (schedules, events, { id }) => getHalfClosedDates(schedules, events, id),
)

export const getSchedulesWithDateRequestable = createSelector(
  getSchedulesForMonth,
  getCurrentUserPublishedEvents,
  getArgs,
  (schedules, events, { id }) => getRequestableDates(schedules, events, id),
)

export const getSchedulesWithDateOpened = createSelector(
  getSchedulesForMonth,
  getCurrentUserPublishedEvents,
  getArgs,
  (schedules, events, { id }) => getOpenedDates(schedules, events, id),
)

export const getSchedulesWithDateInstantBookable = createSelector(
  getSchedulesForMonth,
  getCurrentUserPublishedEvents,
  getArgs,
  (schedules, events, { id }) => getInstantBookingDates(schedules, events, id),
)

// loading
export const fetchingSchedules = (state) =>
  !!getEntitiesState(state)
    .get('loading')
    .find((url) => url.includes('/planning'))
export const fetchingMonthSchedules = (state, mDate) => isFetching(`/planning/${mDate.format('YYYY-MM')}`)(state)
export const fetchingEventMonthSchedules = (state, eventId, mDate) =>
  isFetching(`/events/${eventId}/planning/${mDate.format('YYYY-MM')}`)(state)
export const fetchingHostSchedules = isFetchingRegex(/\/users\/.*\/planning/)

export const updatingSchedules = (state) => isUpdating('/planning')(state)
export const getUpdateScheduleError = (state) => getUpdateError('/planning')(state)

// metadatas getters
export const getSchedulesMetadata = getMetadata('schedules')
