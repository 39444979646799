import { schema } from 'normalizr'
import { FileSchema } from './files'
import { PlaylistRecord } from './playlists'

export const DestinationSchema = new schema.Entity('destinations', {
  cover: FileSchema,
})

export const DestinationRecord = PlaylistRecord
export const emptyDestination = new PlaylistRecord()
