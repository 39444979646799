import { schema } from 'normalizr'
import { Record, Map } from 'immutable'
import { UserSchema } from './users'
import { FileSchema } from './files'

export const ReviewSchema = new schema.Entity('reviews', {
  user: UserSchema,
  files: [FileSchema],
  reviewee: UserSchema,
})

export const ReviewRecord = Record({
  id: undefined,
  reviewee_id: undefined,
  reviewee: undefined,
  booking_id: undefined,
  body: '',
  link: undefined,
  score: undefined,
  ratings: new Map(),
  user: undefined,
  created_at: undefined,
  files: new Set(),
  isPublished: undefined,
  event_id: undefined,
  event_title: undefined,
})

export const emptyReview = new ReviewRecord()
