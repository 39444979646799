import settings from 'settings'
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'
import moment from 'moment'
import i18n from 'i18n-client'

const defaultIntercomSettings = {
  hide_default_launcher: true,
  custom_launcher_selector: '.support-trigger',
  app_id: settings.intercomApiId,
  language_override: i18n.language,
}

function intercomSettingsFromProfile(profile) {
  return {
    user_id: profile.id,
    email: profile.email,
    name: `${profile.firstname} ${profile.lastname}`, // Full name
    address: !profile.place ? '' : profile.place.address,
    city: !profile.place ? '' : profile.place.locality,
    country: !profile.place ? '' : profile.place.country,
    postal_code: !profile.place ? '' : profile.place.postal_code,
    language: profile.language.locale,
    language_override: profile.language.locale,
    phone_number: profile.phone,
    birth_date: profile.birth_date && moment.utc(profile.birth_date).valueOf(),
    profile_description: !!profile.description,
    profile_picture: profile.avatar && profile.avatar.path,
    newsletter: profile.newsletter,
  }
}

const intercomLogin = (intercomSettings) => {
  if (typeof window === 'undefined' || !window.Intercom) return
  window.intercomSettings = intercomSettings
  window.Intercom('shutdown')
  window.Intercom('boot', intercomSettings)
}

export const intercomLogout = () => {
  if (typeof window === 'undefined' || !window.Intercom) return
  window.Intercom('shutdown')
  window.Intercom('boot', defaultIntercomSettings)
}

export function loadStatsForIntercom(profile, stats) {
  if (typeof window === 'undefined' || !window.Intercom) return
  return intercomLogin({
    ...window.intercomSettings,
    ...intercomSettingsFromProfile(profile),
    ...stats,
  })
}

export const manageIntercomDisplay = ({ device = 'mobile', forceHide = undefined }) => {
  if (!window || !window.Intercom || !window.intercomSettings) return
  if (forceHide !== undefined) window.forceHideIntercom = forceHide

  const {
    forceHideIntercom,
    intercomSettings: { hide_default_launcher: isHidden },
  } = window
  // Hide on Mobile devices or if forced to
  const shouldHide = !isHidden && (forceHideIntercom || device === 'mobile')
  // Show on Desktop & Tablet
  const shouldShow = !forceHideIntercom && isHidden && device !== 'mobile'

  // Always check if already shown/hidden to avoid sending too many updates: rate limited to 20 / 30 minutes
  if (forceHideIntercom && isHidden) return
  if (shouldHide) {
    window.intercomSettings.hide_default_launcher = true
    window.Intercom('update', window.intercomSettings)
  } else if (shouldShow) {
    window.intercomSettings.hide_default_launcher = false
    window.Intercom('update', window.intercomSettings)
  }
}

export const loadIntercom = () => {
  if (typeof window === 'undefined') return
  else if (typeof window.Intercom !== 'undefined') return
  else if (loadCookie(COOKIE_KEYS.VIZEAT_ADMIN)) {
    window.Intercom = () => {} // prevent error by setting winodw.Intercom as dummy function
    return
  }
  window.forceHideIntercom = false
  window.intercomSettings = { ...defaultIntercomSettings }
  /* eslint-disable */
  ;(function() {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', intercomSettings)
    } else {
      var d = document
      var i = function() {
        i.c(arguments)
      }
      i.q = []
      i.c = function(args) {
        i.q.push(args)
      }
      w.Intercom = i
      function l() {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/cyythcss'
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else {
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    }
  })()
  /* eslint-enable */
}
