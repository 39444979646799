import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

/**
 * Use a React Router Link component when the URL is relative, on the same hostname or not a string or anchor navigation
 * Use a traditional A tag otherwise (full url on a different hostname, server side etc...)
 */
export const SmartLink = ({ to, ...props }) => {
  // Early return for Link objects or functions
  if (typeof to !== 'string') return <Link to={to} {...props} />

  // Early return for anchors because Link can't handle them
  if (to.startsWith('#')) return <a href={to} {...props} />

  const isClient = typeof window !== 'undefined'
  const isSameHost = isClient && new URL(to, window.location).hostname === window.location?.hostname
  return isSameHost ? <Link to={to} {...props} /> : <a href={to} {...props} />
}

SmartLink.propTypes = {
  // React Router accepts strings, objects and functions.
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
}
