import { schema } from 'normalizr'
import { Record } from 'immutable'

export const ReplySchema = new schema.Entity('replies')

export const ReplyRecord = Record({
  id: undefined,
  conversation_id: undefined,
  sender_id: undefined,
  body: '',
  read: undefined,
  created_at: undefined,
  updated_at: undefined,
})

export const emptyReply = new ReplyRecord()
