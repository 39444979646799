import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching } from './_utils'

export const getDiets = getEntities('diets')
export const getDiet = getEntity('diets')

export const fetchingDiets = isFetching('/diets')

export const getSortedDiets = createSelector(getDiets, (diets) => diets.toList().sortBy(({ title }) => title))

export const getDietsAsSelectOptions = createSelector(getSortedDiets, (diets) =>
  diets.toJS().map(({ title }) => ({ label: title, value: title })),
)
