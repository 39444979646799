import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { UserSchema } from './users'
import { EventSchema } from './events'

export const WishlistSchema = new schema.Entity('wishlists', {
  user: UserSchema,
  events: [EventSchema],
})

export const WishlistRecord = Record({
  id: undefined,
  title: '',
  user_id: '',
  user: undefined,
  events: new List(),
})

export const emptyWishlist = new WishlistRecord()
