/* eslint camelcase: off */
import { createSelector } from 'reselect'
import moment from 'moment'
import { List } from 'immutable'
import { ReservationRecord } from 'redux/schemas'
import {
  getCurrentUser,
  getBookings,
  getRequests,
  getEventWithOverrides,
  getRequest,
  getBooking,
} from 'redux/selectors'

// Redefined here due to circular dependency issues
const getCurrentUserRequests = createSelector(getCurrentUser, getRequests, (user, requests) =>
  requests.filter((request) => request.user_id === user.id).toList(),
)

// Redefined here due to circular dependency issues
const getCurrentUserBookings = createSelector(getCurrentUser, getBookings, (user, bookings) =>
  bookings.filter((booking) => booking.user_id === user.id).toList(),
)

// Args: state, { eventId, requestId, bookingId, date }
export const getReservation = createSelector(
  (state, { eventId, date }) => getEventWithOverrides(state, eventId, date),
  (state, { requestId }) => getRequest(state, requestId),
  (state, { bookingId }) => getBooking(state, bookingId),
  (event, request, booking) => {
    let reservation = new ReservationRecord(request).merge({ request_id: request.id })

    // If the request is approved, the event overrides take precedence
    if (request.approved_at) {
      reservation = reservation.mergeWith((a, b) => (b === undefined ? a : b), event)
    }

    // If there is a booking, the booking details take precedence
    if (booking.id) {
      reservation = reservation
        .merge(booking)
        .merge({ booking_id: booking.id, begins_at: moment.parseZone(booking.begins_at).format('HH:mm') })
    }
    return reservation
  },
)

export const getReservationFromBooking = (state, { id, request_id, event_id, date }) =>
  getReservation(state, { bookingId: id, requestId: request_id, eventId: event_id, date })

export const getReservationFromRequest = (state, { id, event_id, date }) =>
  getReservation(state, { requestId: id, eventId: event_id, date })

export const getReservationsConfirmed = createSelector(
  (state) => state,
  getCurrentUserBookings,
  (state, bookings) =>
    bookings
      .filter(
        ({ groupStatus, date }) =>
          groupStatus === 'successful' && !moment.utc().startOf('day').isAfter(moment.utc(date)),
      )
      .map((booking) => getReservationFromBooking(state, booking))
      .toList(),
)

export const getReservationsPendingApproval = createSelector(
  (state) => state,
  getCurrentUserRequests,
  getCurrentUserBookings,
  (state, requests, bookings) => {
    const pendingRequests = requests
      .filter(({ approved_at, canceled_at, expired_at, date }) => {
        const isPast = date && moment.utc(date).isBefore(moment.utc(), 'day')
        return !approved_at && !canceled_at && !expired_at && date && !isPast
      })
      .map((request) => getReservationFromRequest(state, request))
      .toList()

    const pendingBookings = bookings
      .filter(({ groupStatus, date }) => {
        const isPast = date && moment.utc(date).isBefore(moment.utc(), 'day')
        return groupStatus === 'pending' && date && !isPast
      })
      .map((booking) => getReservationFromBooking(state, booking))
      .toList()

    return pendingRequests.concat(pendingBookings)
  },
)

export const getReservationsAwaitingPayment = createSelector(
  (state) => state,
  getCurrentUserRequests,
  getCurrentUserBookings,
  (state, requests, bookings) => {
    const requestIdsFromBookings = bookings
      .map(({ request_id }) => request_id)
      .filter((x) => !!x)
      .toSet()

    return requests
      .filter(
        ({ id, approved_at, canceled_at, expired_at }) =>
          approved_at && !canceled_at && !expired_at && !requestIdsFromBookings.includes(id),
      )
      .map((request) => getReservationFromRequest(state, request))
      .toList()
  },
)

export const getReservationsAwaitingReview = createSelector(
  (state) => state,
  getCurrentUserBookings,
  (state, bookings) =>
    bookings
      .filter(
        ({ guest_already_left_review, ends_at, status }) =>
          ['completed', 'host_payed'].includes(status) &&
          !guest_already_left_review &&
          moment.utc(ends_at).isBefore(moment.utc()),
      )
      .map((booking) => getReservationFromBooking(state, booking)),
)

export const getReservationsCanceled = createSelector(
  (state) => state,
  getCurrentUserRequests,
  getCurrentUserBookings,
  (state, requests, bookings) => {
    const canceledOrExpiredRequest = requests
      .filter(
        ({ canceled_at, expired_at, canceled_by, user_id, date }) =>
          date && ((canceled_at && canceled_by === user_id) || expired_at),
      )
      .map((request) => getReservationFromRequest(state, request))

    const canceledBookings = bookings
      .filter(
        ({ date, status }) =>
          date &&
          [
            'canceled_by_guest',
            'canceled_by_partner',
            'canceled_under_delay',
            'expired',
            'refunded',
            'partially_refunded',
            'rescheduled',
            'litigation',
            'payment_refused',
          ].includes(status),
      )
      .map((booking) => getReservationFromBooking(state, booking))

    const pastBookings = bookings
      .filter(({ date, guest_already_left_review }) => date && guest_already_left_review)
      .map((booking) => getReservationFromBooking(state, booking))

    return new List().concat(canceledOrExpiredRequest, canceledBookings, pastBookings)
  },
)

export const getReservationsRejected = createSelector(
  (state) => state,
  getCurrentUserRequests,
  getCurrentUserBookings,
  (state, requests, bookings) => {
    const rejectedRequests = requests
      .filter(({ canceled_at, canceled_by, user_id }) => canceled_at && canceled_by !== user_id)
      .map((request) => getReservationFromRequest(state, request))

    const rejectedBookings = bookings
      .filter(({ status }) => ['rejected_by_host', 'canceled_by_host', 'canceled_by_us'].includes(status))
      .map((booking) => getReservationFromBooking(state, booking))

    return new List().concat(rejectedBookings, rejectedRequests)
  },
)
