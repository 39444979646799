// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
// Components
import { ButtonLink } from 'components/ButtonLink'
import { Image } from '@vizeat/components/es6/components/Image'
import { Helmet } from 'react-helmet-async'
// helpers
import { urlFactory } from 'helpers/url'
// styles
import './NotFound.less'

// TODO: Move Pure / Functional components to the component library
export class NotFound extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    message: PropTypes.string,
  }

  static defaultProps = {
    message: undefined,
  }

  render() {
    const { t, message } = this.props
    return (
      <div className='NotFound container'>
        <Helmet
          title={t('NotFound::PageTitle::Not found!')}
          meta={[
            { property: 'og:title', content: t('NotFound::PageTitle::Not found!') },
            { 'http-equiv': 'Status', content: '404 Not Found' }, // this is necessary for the SSR to return a 404 instead of a 200
          ]}
        />
        <Image className='__image' src={require('assets/images/404.png')} />
        <h1>OOPS !</h1>
        <p>{message || t('Common::We could not find what you were looking for')}</p>
        <ButtonLink to={urlFactory.web.home()} large rounded className='__button'>
          {t('Common::Go back to our home page')}
        </ButtonLink>
      </div>
    )
  }
}

export default withTranslation('common')(NotFound)
