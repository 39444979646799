import { schema } from 'normalizr'
import { Record } from 'immutable'
import { BookingSchema } from './bookings'

export const CouponSchema = new schema.Entity(
  'coupons',
  { booking: BookingSchema },
  { idAttribute: (coupon) => `${coupon.campaign_id}-${coupon.booking_id}` },
)

export const CouponRecord = Record({
  booking: undefined,
  booking_id: undefined,
  campaign_id: undefined,
  created_at: '',
  updated_at: '',
  user_id: undefined,
})

export const emptyCoupon = new CouponRecord()
