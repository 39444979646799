import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyleSheetManager } from 'styled-components'
import rtlPlugin from 'stylis-plugin-rtl'
import { withTranslation } from 'react-i18next'

export const RTLContext = createContext({ isRTL: false })

const shouldPageBeViewedInRtl = (i18n) => i18n.dir(i18n.language || 'en') === 'rtl'

const _RtlProvider = ({ i18n, children }) => {
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(i18n.isInitialized && i18n.language)
  const [isRTL, setIsRTL] = useState(isLanguageInitialized && shouldPageBeViewedInRtl(i18n))

  useEffect(() => {
    const handleI18nInitialization = () => {
      setIsLanguageInitialized(true)
      setIsRTL(shouldPageBeViewedInRtl(i18n))
      document.documentElement.dir = shouldPageBeViewedInRtl(i18n) ? 'rtl' : 'ltr'
      i18n.off('initialized', handleI18nInitialization)
    }

    if (!isLanguageInitialized) i18n.on('initialized', handleI18nInitialization)
  }, [i18n, isLanguageInitialized])

  return (
    <RTLContext.Provider value={{ isRTL }}>
      <StyleSheetManager stylisPlugins={isRTL ? [rtlPlugin] : []}>{children}</StyleSheetManager>
    </RTLContext.Provider>
  )
}

_RtlProvider.propTypes = {
  i18n: PropTypes.shape({
    isInitialized: PropTypes.bool,
    language: PropTypes.string,
    on: PropTypes.func,
    off: PropTypes.func,
  }).isRequired,
  children: PropTypes.any,
}

export const RtlProvider = withTranslation()(_RtlProvider)
