import { createSelector } from 'reselect'
import { getUser, getBooking, getFiles } from 'redux/selectors'
import {
  getEntities,
  getEntity,
  isFetchingRegex,
  isCreating,
  getMetadata,
  getFetchError,
  getCreateError,
  getEntityOrder,
  getObjId,
} from './_utils'

// getters
export const getReviews = getEntities('reviews')
export const getReview = getEntity('reviews')

export const getReviewer = createSelector(
  getReview,
  (state) => state,
  (review, state) => getUser(state, getObjId(review, 'user')),
)

export const getReviewee = createSelector(
  getReview,
  (state) => state,
  (review, state) => getUser(state, getObjId(review, 'reviewee')),
)

export const getReviewBooking = createSelector(
  getReview,
  (state) => state,
  (review, state) => getBooking(state, getObjId(review, 'booking')),
)

export const getBookingReview = createSelector(
  getReviews,
  (_, { reviewerId, bookingId }) => ({
    reviewerId,
    bookingId,
  }),
  (reviews, { reviewerId, bookingId }) =>
    reviews.find((review) => review.get('user') === reviewerId && review.get('booking_id') === bookingId),
)

export const getReviewFiles = createSelector(getReview, getFiles, (review, files) =>
  files.filter((file) => file.attachable_type === 'reviews' && file.attachable_id === review.id).toList(),
)

// metadata
export const getReviewsMetadata = getMetadata('reviews')
export const getReviewsList = createSelector(
  getEntityOrder('reviews'),
  (state) => state,
  (ids, state) => ids.map((id) => getReview(state, id)),
)

// notifiers
export const fetchingReviews = isFetchingRegex(/\/reviews/)
export const creatingReview = (state, id) => isCreating(`/bookings/${id}/reviews`)(state)
export const getFetchReviewsError = getFetchError('/reviews')
export const getCreateReviewError = (state, id) => getCreateError(`/bookings/${id}/reviews`)(state)
