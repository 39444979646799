import { createSelector } from 'reselect'
import { getEntities, isFetching, getFetchError } from './_utils'

export const getCriteria = getEntities('criteria')

export const getMaxRatedGuestCriteria = createSelector(
  getCriteria,
  (_, score) => ({ score }),
  (criteria, { score }) =>
    criteria
      .filter((criterion) => criterion.is_left_by_reviewer)
      .map((c) => ({
        id: c.get('id'),
        title: c.get('title'),
        score,
      }))
      .toArray(),
)

export const getMaxRatedHostCriteria = createSelector(
  getCriteria,
  (_, score) => ({ score }),
  (criteria, { score }) =>
    criteria
      .filter((criterion) => criterion.is_left_by_reviewee)
      .map((c) => ({
        id: c.get('id'),
        title: c.get('title'),
        score,
      }))
      .toArray(),
)

// notifiers
export const fetchingCriteria = isFetching('/criteria')

// errors
export const getFetchCriteriaError = getFetchError('/criteria')
