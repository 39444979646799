import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@vizeat/components/es6/components/Button'
import { SmartLink } from './SmartLink'

export function ButtonLink(props) {
  return props.to ? <Button as={SmartLink} {...props} /> : <Button {...props} />
}

ButtonLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
}

ButtonLink.defaultProps = {
  to: undefined,
}
