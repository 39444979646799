import { ActionsCreator } from '../lib'
import { screenApi } from 'apis'
import { NoteSchema, ScheduleRecord } from '../schemas'
import { Map } from 'immutable'
import moment from 'moment'

export const noteActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'notes' })

export const updateNote = (id, note) =>
  noteActions.update({
    url: `/notes/${id}`,
    payload: {
      note,
    },
    schema: { note: NoteSchema },
  })

export const deleteNote = (id) =>
  noteActions.delete({
    url: `/notes/${id}`,
    deleted: { notes: [id] },
  })

export const createNote = (date, note) =>
  noteActions.create({
    url: '/notes',
    payload: {
      date: moment.utc(date).format('YYYY-MM-DD'),
      note,
    },
    schema: { note: NoteSchema },
  })

export const notesCustomHandlers = {
  [noteActions.actionTypes.DELETED]: (state, actions) => {
    const schedules = state.getIn(['entities', 'schedules'])
    const deletedNoteId = actions.payload.deleted.notes[0]

    const updatedSchedules = schedules.map((schedule) => {
      const noteId = schedule.note_id
      return noteId === deletedNoteId ? schedule.remove('note_id') : schedule
    })

    return state.setIn(['entities', 'schedules'], new Map(updatedSchedules))
  },

  [noteActions.actionTypes.CREATED]: (state, actions) => {
    const date = actions.payload.payload.date
    const noteId = actions.payload.data.result.note
    const schedule = state.getIn(['entities', 'schedules']).get(date, new ScheduleRecord())

    return schedule
      ? state.mergeIn(['entities', 'schedules'], { [date]: new ScheduleRecord(schedule.set('note_id', noteId)) })
      : state
  },
}
