// React
import React from 'react'
import { Route, IndexRedirect, IndexRoute, Redirect } from 'react-router'
import { LoadableWrapper } from 'helpers/loadable'
import { AppLayout, DashboardLayout } from 'layouts'
import NotFound from 'components/NotFound'

const today = new Date()

export function getRoutes() {
  return (
    <Route component={AppLayout}>
      <Redirect from='/' to='/login' />

      <Route path='change-password'>
        <Route
          path=':token'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteChangePassword" */ 'pages/change-password/[token]'
              ),
            'pages/change-password/[token]',
            'ChangePasswordPage',
          )}
        />
      </Route>
      <Route
        path='forgotten-password'
        component={LoadableWrapper(
          () =>
            import(
              // eslint-disable-next-line comma-dangle
              /* webpackChunkName: "RouteForgottenPassword" */ 'pages/forgotten-password'
            ),
          'pages/forgotten-password',
          'ForgottenPassword',
        )}
      />
      <Route
        path='login'
        component={LoadableWrapper(
          () =>
            import(
              // eslint-disable-next-line comma-dangle
              /* webpackChunkName: "RouteLogin" */ 'pages/login'
            ),
          'pages/login',
          'LoginPage',
        )}
      />
      <Route
        path='sign-up'
        component={LoadableWrapper(
          () =>
            import(
              // eslint-disable-next-line comma-dangle
              /* webpackChunkName: "RouteSignUp" */ 'pages/sign-up'
            ),
          'pages/sign-up',
          'SignUpPage',
        )}
      />

      <Route
        path='weibo-callback'
        component={LoadableWrapper(
          () =>
            import(
              // eslint-disable-next-line comma-dangle
              /* webpackChunkName: "RouteWeiboCallback" */ 'pages/weibo-callback'
            ),
          'pages/weibo-callback',
          'WeiboCallbackPage',
        )}
      />

      <Route path='dashboard' component={DashboardLayout}>
        <Route
          path='profile'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardProfile" */ 'pages/dashboard/profile'
              ),
            'pages/dashboard/profile',
          )}
        >
          <IndexRedirect to='personal-info' />
          <Route
            path='personal-info'
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardProfilePersonalInfo" */ 'pages/dashboard/profile/personal-info'
                ),
              'pages/dashboard/profile/personal-info',
              'EditPersonalInfoPage',
            )}
          />
          <Route path='reviews'>
            <IndexRedirect to='received' />
            <Route
              path=':type'
              component={LoadableWrapper(
                () =>
                  import(
                    // eslint-disable-next-line comma-dangle
                    /* webpackChunkName: "RouteDashboardProfileReviews" */ 'pages/dashboard/profile/reviews'
                  ),
                'pages/dashboard/profile/reviews',
                'ProfileReviews',
              )}
            />
          </Route>
        </Route>

        <Route path='inbox'>
          <IndexRoute
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardInbox" */ 'pages/dashboard/inbox'
                ),
              'pages/dashboard/inbox',
            )}
          />
          <Route
            path=':id'
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardInboxConversation" */ 'pages/dashboard/inbox/id'
                ),
              'pages/dashboard/inbox/id',
            )}
          />
        </Route>

        <Route path='my-meals'>
          <IndexRoute
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardExperiences" */ 'pages/dashboard/my-meals'
                ),
              'pages/dashboard/my-meals',
              'MyMeals',
            )}
          />
          {['create', 'edit/:id'].map((path, index) => (
            <Route
              path={path}
              key={index}
              component={LoadableWrapper(
                () =>
                  import(
                    // eslint-disable-next-line comma-dangle
                    /* webpackChunkName: "RouteDashboardExperienceForm" */ 'pages/dashboard/my-meals/create-edit'
                  ),
                'pages/dashboard/my-meals/create-edit',
              )}
            >
              <IndexRedirect to='profile' />
              <Route
                path='profile'
                component={LoadableWrapper(
                  () =>
                    import(
                      // eslint-disable-next-line comma-dangle
                      /* webpackChunkName: "RouteDashboardExperienceFormProfile" */ 'pages/dashboard/my-meals/create-edit/profile'
                    ),
                  'pages/dashboard/my-meals/create-edit/profile',
                  'EditProfileForm',
                )}
              />
              <Route
                path='payout-preferences'
                component={LoadableWrapper(
                  () =>
                    import(
                      // eslint-disable-next-line comma-dangle
                      /* webpackChunkName: "RouteDashboardExperienceFormPayoutPreferences" */ 'pages/dashboard/my-meals/create-edit/payout-preferences'
                    ),
                  'pages/dashboard/my-meals/create-edit/payout-preferences',
                  'PayoutPreferences',
                )}
              />
              <Route
                path='description'
                component={LoadableWrapper(
                  () =>
                    import(
                      // eslint-disable-next-line comma-dangle
                      /* webpackChunkName: "RouteDashboardExperienceFormDescription" */ 'pages/dashboard/my-meals/create-edit/description'
                    ),
                  'pages/dashboard/my-meals/create-edit/description',
                )}
              />
              <Route
                path='photos'
                component={LoadableWrapper(
                  () =>
                    import(
                      // eslint-disable-next-line comma-dangle
                      /* webpackChunkName: "RouteDashboardExperienceFormPhotos" */ 'pages/dashboard/my-meals/create-edit/photos'
                    ),
                  'pages/dashboard/my-meals/create-edit/photos',
                  'Photos',
                )}
              />
              <Route
                path='place-and-amenities'
                component={LoadableWrapper(
                  () =>
                    import(
                      // eslint-disable-next-line comma-dangle
                      /* webpackChunkName: "RouteDashboardExperienceFormPlaceAndAmenities" */ 'pages/dashboard/my-meals/create-edit/place-and-amenities'
                    ),
                  'pages/dashboard/my-meals/create-edit/place-and-amenities',
                )}
              />
            </Route>
          ))}
        </Route>

        <Route
          path='bookings'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardBookings" */ 'pages/dashboard/bookings'
              ),
            'pages/dashboard/bookings',
            'MyReservations',
          )}
        >
          {/* Legacy redirect */}
          <Redirect from=':profileAs' to='/dashboard/bookings' />
          <Redirect from=':profileAs/:filter' to='/dashboard/bookings' query={{ review: true }} />
        </Route>

        <Route
          path='account'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardAccount" */ 'pages/dashboard/account'
              ),
            'pages/dashboard/account',
            'DashboardAccount',
          )}
        >
          <IndexRedirect to='payout-preferences' />
          <Route
            path='payout-preferences'
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardAccountPayoutPreferences" */ 'pages/dashboard/account/payout-preferences'
                ),
              'pages/dashboard/account/payout-preferences',
              'PayoutPreferences',
            )}
          />
          <Route
            path='payment-methods'
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardAccountPaymentMethods" */ 'pages/dashboard/account/payment-methods'
                ),
              'pages/dashboard/account/payment-methods',
              'PaymentMethods',
            )}
          />
          <Route
            path='security'
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardAccountSecurity" */ 'pages/dashboard/account/security'
                ),
              'pages/dashboard/account/security',
            )}
          />
        </Route>

        <Route
          path='payment-history'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardAccountPaymentHistory" */ 'pages/dashboard/account/payment-history'
              ),
            'pages/dashboard/account/payment-history',
          )}
        />

        <Route path='planning'>
          <IndexRedirect to={`${today.getFullYear()}/${today.getMonth() + 1}`} />

          <Route path='edit'>
            <IndexRedirect to={`${today.getFullYear()}/${today.getMonth() + 1}`} />
            <Route
              path=':year/:month'
              component={LoadableWrapper(
                () =>
                  import(
                    // eslint-disable-next-line comma-dangle
                    /* webpackChunkName: "RouteDashboardPlanning" */ 'pages/dashboard/planning'
                  ),
                'pages/dashboard/planning',
              )}
            />
          </Route>

          <Route
            path=':year/:month(/:day(/event/:id))'
            component={LoadableWrapper(
              () =>
                import(
                  // eslint-disable-next-line comma-dangle
                  /* webpackChunkName: "RouteDashboardPlanning" */ 'pages/dashboard/planning'
                ),
              'pages/dashboard/planning',
            )}
          />
        </Route>

        <Route
          path='wishlists'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardWishlists" */ 'pages/dashboard/wishlists'
              ),
            'pages/dashboard/wishlists',
          )}
        />

        <Route
          path='referral'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardReferral" */ 'pages/dashboard/referral'
              ),
            'pages/dashboard/referral',
          )}
        />

        <Route
          path='widget'
          component={LoadableWrapper(
            () =>
              import(
                // eslint-disable-next-line comma-dangle
                /* webpackChunkName: "RouteDashboardWidget" */ 'pages/dashboard/widget'
              ),
            'pages/dashboard/widget',
            'WidgetInstructionsPage',
          )}
        />
      </Route>
      <Route
        path='host-application'
        component={LoadableWrapper(
          () => import(/* webpackPrefetch: true, webpackChunkName: "Welcome" */ 'pages/host-application'),
          'pages/host-application',
          'HostApplication',
        )}
      >
        <IndexRedirect to='welcome' />
        <Route
          path='welcome'
          component={LoadableWrapper(
            () => import(/* webpackPrefetch: true, webpackChunkName: "Welcome" */ 'pages/host-application/welcome'),
            'pages/host-application/welcome',
            'Welcome',
          )}
        />
        <Route
          path='about-you'
          component={LoadableWrapper(
            () =>
              import(/* webpackPrefetch: true, webpackChunkName: "AboutYou" */ 'pages/host-application/StepAboutYou'),
            'pages/host-application/StepAboutYou',
            'AboutYou',
          )}
        />
        <Route
          path='your-motivations'
          component={LoadableWrapper(
            () =>
              import(
                /* webpackPrefetch: true, webpackChunkName: "Motivations" */ 'pages/host-application/StepMotivations'
              ),
            'pages/host-application/StepMotivations',
            'Motivations',
          )}
        />
        <Route
          path='your-story'
          component={LoadableWrapper(
            () =>
              import(/* webpackPrefetch: true, webpackChunkName: "YourStory" */ 'pages/host-application/StepYourStory'),
            'pages/host-application/StepYourStory',
            'YourStory',
          )}
        />
        <Route
          path='your-culinary-experience'
          component={LoadableWrapper(
            () =>
              import(
                /* webpackPrefetch: true, webpackChunkName: "YourCulinaryExperience" */ 'pages/host-application/StepYourCulinaryExperience'
              ),
            'pages/host-application/StepYourCulinaryExperience',
            'YourCulinaryExperience',
          )}
        />
        <Route
          path='your-venue'
          component={LoadableWrapper(
            () =>
              import(/* webpackPrefetch: true, webpackChunkName: "YourVenue" */ 'pages/host-application/StepYourVenue'),
            'pages/host-application/StepYourVenue',
            'YourVenue',
          )}
        />
        <Route
          path='your-photos'
          component={LoadableWrapper(
            () =>
              import(
                /* webpackPrefetch: true, webpackChunkName: "YourPhotos" */ 'pages/host-application/StepYourPhotos'
              ),
            'pages/host-application/StepYourPhotos',
            'YourPhotos',
          )}
        />
        <Route
          path='our-policy-and-terms-and-conditions'
          component={LoadableWrapper(
            () =>
              import(
                /* webpackPrefetch: true, webpackChunkName: "TermsAndConditions" */ 'pages/host-application/StepTermsAndConditions'
              ),
            'pages/host-application/StepTermsAndConditions',
            'TermsAndConditions',
          )}
        />
        <Route
          path='congratulations'
          component={LoadableWrapper(
            () =>
              import(
                /* webpackPrefetch: true, webpackChunkName: "Congratulations" */ 'pages/host-application/StepCongratulations'
              ),
            'pages/host-application/StepCongratulations',
            'Congratulations',
          )}
        />
      </Route>

      <Route path='*' component={NotFound} />
    </Route>
  )
}
