import { schema } from 'normalizr'
import { Record, List, Set, Map } from 'immutable'

import { AccountSchema } from './accounts'
import { FileSchema } from './files'
import { CurrencySchema } from './currencies'
import { LanguageSchema } from './languages'
import { FoodSchema } from './foods'
import { DietSchema } from './diets'
import { PartnerSchema } from './partners'

export const CurrentUserSchema = new schema.Entity('currentUsers', {
  account: AccountSchema,
  avatar: FileSchema,
  currency: CurrencySchema,
  partner: PartnerSchema,
  diets: [DietSchema],
  files: [FileSchema],
  foods_cooked: [FoodSchema],
  foods_liked: [FoodSchema],
  language: LanguageSchema,
  languages: [LanguageSchema],
})

export const CurrentUserRecord = Record({
  id: undefined,

  account: undefined,
  account_id: undefined,
  age: '',
  avatar: undefined,
  billing_country: undefined,
  billing_country_iso: undefined,
  billing_address: undefined,
  birth_date: '',
  civility: '',
  country: undefined,
  country_iso: undefined,
  currency: undefined,
  currency_id: undefined,
  description: '',
  descriptions: new Map(),
  diets: new List(),
  email: '',
  events: new List(),
  files: new Set(),
  firstname: '',
  foods_cooked: new List(),
  foods_liked: new List(),
  host_application_id: undefined,
  language: undefined,
  languages: new List(),
  lastname: '',
  locality: undefined,
  newsletter: false,
  partner: undefined,
  phone: undefined,
  payout_gateway: undefined,
  paypal_id: undefined,
  place_id: undefined,
  properties: new Map(),
  rating: new Map(),
  isHost: false,
  isHostCandidate: false,
  isActiveHost: false,
  isNewHost: false,
  tos: false,
  hasValidatedHygieneGuidelines: undefined,

  created_at: undefined,
  updated_at: undefined,
})

export const emptyCurrentUser = new CurrentUserRecord()
