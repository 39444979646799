import { createSelector } from 'reselect'
import { getEntitiesState } from './_utils'
import { Map, List } from 'immutable'

export const getGTM = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('GTM') || Map())

export const getGTMInitialized = createSelector(getGTM, (gtm) => gtm.get('initialized') || false)

export const getGTMDataLayerVars = createSelector(getGTM, (gtm) => gtm.get('dataLayerVars'))

export const getGTMThunks = createSelector(getGTM, (gtm) => gtm.get('thunks') || Map())

export const getGTMThunk = createSelector(
  getGTMThunks,
  (state, thunkName) => thunkName,
  (thunks, thunkName) => thunks.get(thunkName) || List(),
)
