import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { ReplySchema, ConversationRecord } from 'redux/schemas'

export const repliesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'replies' })

export const createReply = (conversationId, payload, query) =>
  repliesActions.create({
    url: `/conversations/${conversationId}/replies`,
    payload,
    query,
    schema: { reply: ReplySchema },
  })

export function createMultipleReplies(conversationsIds, payload, query) {
  return (dispatch, getState) => Promise.all(conversationsIds.map((id) => dispatch(createReply(id, payload, query))))
}

export const repliesCustomHandlers = {
  [repliesActions.actionTypes.CREATED]: (state, action) => {
    const {
      payload: {
        data: { entities, result },
      },
    } = action
    const replyId = result.reply
    const conversationId = entities.replies[replyId].conversation_id
    // Ensure having a conversation Record in the store, otherwise a Map is created and never replaced
    const conversation = state
      .getIn(['entities', 'conversations', String(conversationId)], new ConversationRecord({ id: conversationId }))
      .updateIn(['replies'], (value) => value.push(replyId))
    return state.setIn(['entities', 'conversations', String(conversationId)], conversation)
  },
}
