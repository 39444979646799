import { isCreating, getCreateError, isUpdating, getUpdateError, isCreatingRegex, getCreateErrorRegex } from './_utils'

// notifiers
export const creatingRenewPassword = isCreating('/password_renewal')
export const updatingRenewPassword = isUpdating('/password_renewal')
export const getCreateRenewPasswordError = getCreateError('/password_renewal')
export const getUpdateRenewPasswordError = getUpdateError('/password_renewal')

export const validatingAccount = isCreating('/accounts/validate')
export const getValidateAccountError = getCreateError('/accounts/validate')

// register
export const registeringUser = isCreating('/register')
export const getRegisterError = getCreateError('/register')

// login
export const loggingIn = isCreatingRegex(/\/login/)
export const getLoginError = getCreateErrorRegex(/\/login/)
