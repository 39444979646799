import { schema } from 'normalizr'
import { Record } from 'immutable'

export const NoteSchema = new schema.Entity('notes')
export const NoteRecord = Record({
  id: undefined,
  date: undefined,
  note: '',
})

export const emptyNote = new NoteRecord()
