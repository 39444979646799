import { konstants } from '@vizeat/helpers'
const { HOST_APPLICATION_SCHEMA_VERSION } = konstants

export const STEPS = [
  '/host-application/welcome',
  '/host-application/about-you',
  '/host-application/your-motivations',
  '/host-application/your-story',
  '/host-application/your-culinary-experience',
  '/host-application/your-venue',
  '/host-application/your-photos',
  '/host-application/our-policy-and-terms-and-conditions',
  '/host-application/congratulations',
]

export const getPrevStep = (pathname) => {
  const currentIndex = STEPS.indexOf(pathname)
  return STEPS[currentIndex - 1]
}

export const getNextStep = (pathname) => {
  const currentIndex = STEPS.indexOf(pathname)
  return STEPS[currentIndex + 1]
}

export const goToNextStep = ({ router }) => {
  const location = router.getCurrentLocation()
  router.push(getNextStep(location.pathname))
}

const STEP_PROGRESS = {
  welcome: null,
  'about-you': { completionRate: 0 },
  'your-motivations': { completionRate: 14 },
  'your-story': { completionRate: 28 },
  'your-culinary-experience': { completionRate: 42 },
  'your-venue': { completionRate: 57 },
  'your-photos': { completionRate: 71 },
  'our-policy-and-terms-and-conditions': { completionRate: 85 },
  congratulations: { completionRate: 100 },
}

export const getStepProgress = (pathname) => {
  const path = pathname.replace('/host-application/', '')
  return STEP_PROGRESS[path]
}

function isStepValid(pathname, data, userFiles) {
  const path = pathname.replace('/host-application/', '')
  const host = data.get('host')
  const experience = data.get('sample_experience')
  const metadata = data.get('metadata')

  switch (path) {
    case 'welcome':
    case 'about-you':
      return true
    case 'your-motivations':
      return host.get('spoken_languages').size > 0
    case 'your-story':
      return !!host.get('host_typology')
    case 'your-culinary-experience':
      return !!host.get('culinary_expertise') && !!host.get('description')
    case 'your-venue':
      return (
        !!host.getIn(['availabilities', 'frequency']) &&
        experience &&
        !!experience.get('experience_type') &&
        !!experience.getIn(['description', 'event_description'])
      )
    case 'your-photos':
      return (
        experience &&
        !!experience.getIn(['place', 'place_type']) &&
        !!experience.getIn(['description', 'neighbourhood'])
      )
    case 'our-policy-and-terms-and-conditions':
      return userFiles.size >= 5
    case 'congratulations':
      return metadata && (metadata.get('status') === 'to review' || metadata.get('status') === 're-submitted')
    default:
      return false
  }
}

export function getRedirectStep(pathname, data, userFiles, fromLastStep = false) {
  const metadata = data.get('metadata')
  if (metadata.get('status') && metadata.get('schema_version') === `${HOST_APPLICATION_SCHEMA_VERSION}`) {
    // application needs changes, go to Sample Experience section
    if (metadata.get('status').toLowerCase() === 'rejected') {
      return '/host-application/your-motivations'
    }
    // to review, re-submitted or approved, go to last page
    if (metadata.get('status').toLowerCase() !== 'draft') {
      return STEPS[STEPS.length - 1]
    }
  }

  let lastValidStep
  const currentIndex = STEPS.indexOf(pathname)
  const stepsToCheck = fromLastStep ? STEPS : STEPS.slice(0, currentIndex + 1)

  for (const step of stepsToCheck) {
    if (!isStepValid(step, data, userFiles)) break
    lastValidStep = step
  }

  return lastValidStep
}
