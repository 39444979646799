import { createSelector } from 'reselect'
import { getEntities, getEntity } from './_utils'
import { getEvent } from 'redux/selectors'

export const getOverrideId = (eventId, dateString) => `${eventId}-${dateString}`

export const getOverrides = getEntities('overrides')
export const getOverride = getEntity('overrides')

export const hasOverrides = createSelector(
  getOverride,
  (state, key) => getEvent(state, key.replace(/-.*/g, '')),
  (override, event) =>
    override
      .toSeq()
      .toMap()
      .filter(
        (value, key) => value !== undefined && key !== 'date' && key !== 'event_id' && event.allow_public_bookings,
      ).size > 0,
)

export const hasOverride = createSelector(
  getOverride,
  (state, id, key) => key,
  (override, key) => override.get(key) !== undefined,
)
