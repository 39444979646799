import { schema } from 'normalizr'
import { Record } from 'immutable'

export const CriterionSchema = new schema.Entity('criteria')
export const CriterionRecord = Record({
  id: undefined,
  title: '',
  description: '',
  active: undefined,
  is_left_by_reviewer: false,
  is_left_by_reviewee: false,
})

export const emptyCriterion = new CriterionRecord()
