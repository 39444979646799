import React from 'react'
import { fromJS } from 'immutable'
import { HelmetProvider } from 'react-helmet-async'
// Router
import { Router, browserHistory } from 'react-router'
// Redux
import { Provider } from 'react-redux'
import { syncHistoryWithStore, replace } from 'react-router-redux'
import configStore from 'redux/configStore'
import { recordsByEntities } from 'redux/schemas'
import { getRouterStateAsJS, getPrevLocation } from 'redux/selectors'
// Routes
import { getRoutes } from 'pages/router'
// i18n
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n-client'
// Style
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { eatwithTheme } from '@vizeat/components/es6/theme'
import 'less/theme.less'
// Intercom
import { loadIntercom } from 'intercom'
// Helpers
import { RtlProvider } from 'helpers/rtl'
import { GlobalModalProvider } from 'hooks/useGlobalModal'

loadIntercom()

function parse(state) {
  if (!state) return state
  // deeply immutable state
  const immutableState = fromJS(state)
  const reducerState = immutableState.set('loading', immutableState.get('loading').toSet())
  const entities = reducerState.get('entities').map((entity, entityName) => {
    return entity.map((value, id) => new recordsByEntities[entityName](value))
  })
  return reducerState.set('entities', entities)
}

const preloadedState = typeof window !== 'undefined' && (parse(window.__data) || undefined)

const { store } = configStore({ preloadedState, historyStrategy: browserHistory })
const routes = getRoutes()
const selectLocationState = (state) => getRouterStateAsJS(state)
const history = syncHistoryWithStore(browserHistory, store, { selectLocationState })

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.text};
    color: ${({ theme }) => theme.colors.title};
    background-color: ${({ theme }) => theme.colors.white};
  }
  .Header {
    .__navItem {
      &.--isHighlighted {
        background-color: ${({ theme }) => theme.colors.primary};
        transition: filter 0.4s;
        &:hover {
          filter: brightness(0.90);
        }
      }
    }
  }
`

export default function Client() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={eatwithTheme}>
        <GlobalStyle />
        <I18nextProvider i18n={i18n}>
          <RtlProvider>
            <Provider store={store}>
              <GlobalModalProvider>
                <Router history={history}>{routes}</Router>
              </GlobalModalProvider>
            </Provider>
          </RtlProvider>
        </I18nextProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

// whitelist queries are queries that, once set, should NEVER be overrided
// during the whole navigation
const whitelistQueries = ['brand']
const findQueryInSearch = (qName, search) => {
  let q = search.slice(search.indexOf(`${qName}=`))
  if (q.indexOf('&') !== -1) q = q.slice(0, q.indexOf('&'))
  return q.split('=')[1]
}

history.listen((l) => {
  const state = store.getState()
  if (!getPrevLocation(state)) return

  const prevSearch = getPrevLocation(state).search
  const appendQueries = {}
  // Ensure whitlisted queries if they already exist
  whitelistQueries.forEach((qKey) => {
    if (prevSearch.includes(`${qKey}=`) && !l.query[qKey]) {
      appendQueries[qKey] = findQueryInSearch(qKey, prevSearch)
    }
  })

  // Ensure we remove the currency
  if (l.query.c !== undefined) appendQueries.c = undefined

  if (Object.keys(appendQueries).length) {
    store.dispatch(
      replace({
        ...l,
        query: { ...l.query, ...appendQueries },
      }),
    )
  }
})
