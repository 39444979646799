import { createSelector } from 'reselect'
import { getEntitiesState } from './_utils'
import { Map } from 'immutable'
import { breakpoints } from 'less/variables'

const getBrowserSettings = createSelector(
  getEntitiesState,
  (entitiesState) => entitiesState.get('browserSettings') || Map(),
)

const getBrowserLanguage = createSelector(
  getBrowserSettings,
  (settings) => settings.get('getBrowserLanguage') || 'en-US',
)

export const getBrowserLocale = createSelector(getBrowserLanguage, (language) => language.substr(0, 2) || null)

export const getOS = createSelector(getBrowserSettings, (settings) => settings.get('OS'))

export const getDeviceIs = createSelector(
  getBrowserSettings,
  (settings) =>
    settings.get('is') ||
    Map(
      Object.entries(breakpoints).reduce((acc, [key, _]) => {
        acc[key] = ['xs', 'phablet', 'mobile'].includes(key) // for SSR, set view for xs/mobile/phablet by default
        return acc
      }, {}),
    ),
)

export const isMobile = createSelector(
  getDeviceIs,
  (device) => device.get('xs') || device.get('sm') || device.get('mobile') || device.get('tablet'),
)
