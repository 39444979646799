import { debounce } from 'lodash'
import { deviceIs } from 'less/variables'
import moment from 'moment'
import i18n from 'i18n-client'
import { Map } from 'immutable'

export const browserSettingsActions = {
  UPDATE_WINDOW_DATA: '@@browserSettings/UPDATE_WINDOW_DATA',
  INIT_SETTINGS: '@@browserSettings/INIT_SETTINGS',
}

const setIs = (width) => ({
  mobile: deviceIs('mobile', width),
  phablet: deviceIs('phablet', width),
  tablet: deviceIs('tablet', width),
  desktop: deviceIs('desktop', width),
  large: deviceIs('large', width),
  xs: deviceIs('xs', width),
  sm: deviceIs('sm', width),
  md: deviceIs('md', width),
  lg: deviceIs('lg', width),
  xl: deviceIs('xl', width),
})

const updateWindowData = debounce(
  (dispatch) =>
    dispatch({
      type: browserSettingsActions.UPDATE_WINDOW_DATA,
      payload: {
        is: setIs(window.innerWidth),
      },
    }),
  200,
)

const setDeviceOS = (userAgent = '') => {
  userAgent = userAgent.toLowerCase()
  if (userAgent.includes('windows phone')) return 'windows'
  if (userAgent.includes('android')) return 'android'
  if (userAgent.includes('iphone') || userAgent.includes('ipod') || userAgent.includes('ipad')) return 'ios'
  return undefined
}

export const initBrowserSettings = (windw) => {
  if (typeof windw === 'undefined') return
  return (dispatch) => {
    windw.addEventListener('resize', (e) => updateWindowData(dispatch))
    const browserLanguage =
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage ||
      window.navigator.systemLanguage

    moment.locale('en-GB') // default
    if (i18n.language === 'en' && browserLanguage.startsWith('en')) {
      moment.locale(browserLanguage)
    } else {
      moment.locale(i18n.language)
    }

    dispatch({
      type: browserSettingsActions.INIT_SETTINGS,
      payload: {
        is: setIs(windw.innerWidth),
        OS: setDeviceOS(windw.navigator.userAgent),
        browserLanguage,
      },
    })
  }
}

// handlers
const handleInit = (state, { payload }) => {
  return state
    .setIn(['browserSettings', 'is'], Map(payload.is))
    .setIn(['browserSettings', 'OS'], payload.OS)
    .setIn(['browserSettings', 'browserLanguage'], payload.browserLanguage)
}

const handleWindowData = (state, { payload }) => {
  return state.setIn(['browserSettings', 'is'], Map(payload.is))
}

export const browserSettingsCustomHandlers = {
  [browserSettingsActions.INIT_SETTINGS]: handleInit,
  [browserSettingsActions.UPDATE_WINDOW_DATA]: handleWindowData,
}
