import { validateCurrency, konstants } from '@vizeat/helpers'
// libs
import { screenApi } from 'apis'
import i18n from 'i18n-client'
// helpers
import { COOKIE_KEYS, saveCookie, loadCookie } from 'helpers/cookies'
// redux
import { gtmLangCurrency, fetchAmenities, fetchAlcohols, fetchCriteria, fetchDiets, fetchFoods } from 'redux/actions'

const { DEFAULT_CURRENCY } = konstants

const entitiesToFetchOnSetLocale = [
  () => fetchAmenities(),
  () => fetchAlcohols(),
  () => fetchCriteria(),
  () => fetchDiets(),
  () => fetchFoods(),
]

export const settingsActions = {
  SET_CURRENCY: '@@appSettings/SET_CURRENCY',
  SET_LOCALE: '@@appSettings/SET_LOCALE',
  APP_INITIALISED: '@@appSettings/APP_INITIALISED',
}

export const setAppCurrency = (iso3) => (dispatch, getState) => {
  const prevIso3 = loadCookie(COOKIE_KEYS.EW_CURRENCY)
  const nextIso3 = validateCurrency(iso3) ? iso3 : prevIso3 || DEFAULT_CURRENCY.iso_3

  saveCookie({
    name: COOKIE_KEYS.EW_CURRENCY,
    value: nextIso3,
    duration: 3600 * 24 * 365,
  })

  screenApi.setHeader('X-Currency', nextIso3)
  if (prevIso3 !== nextIso3) dispatch(gtmLangCurrency(nextIso3))
  return dispatch({ type: settingsActions.SET_CURRENCY, currency: nextIso3 })
}

export const setAppLocale =
  (locale = i18n.language) =>
  (dispatch) => {
    screenApi.setHeader('X-Language', locale)

    saveCookie({
      name: COOKIE_KEYS.EW_LANGUAGE,
      value: locale,
      duration: 3600 * 24 * 365,
    })

    entitiesToFetchOnSetLocale.map((action) => dispatch(action()))
    return i18n.changeLanguage(locale, () => dispatch({ type: settingsActions.SET_LOCALE, locale }))
  }

export const appInitialised = () => (dispatch) => {
  return dispatch({ type: settingsActions.APP_INITIALISED })
}

// handlers
const handleCurrency = (state, action) => state.setIn(['appSettings', 'currency'], action.currency)
const handleLocale = (state, action) => state.setIn(['appSettings', 'locale'], action.locale)
const handleInit = (state, action) => state.setIn(['appSettings', 'appInitialised'], true)

export const appSettingsCustomHandlers = {
  [settingsActions.SET_CURRENCY]: handleCurrency,
  [settingsActions.SET_LOCALE]: handleLocale,
  [settingsActions.APP_INITIALISED]: handleInit,
}
