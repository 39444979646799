import moment from 'moment'

export function formatTime(time) {
  return moment.utc(time, 'HH:mm').format('LT')
}

export function formatDateAndTime({ mDate, time, format = 'LLLL' }) {
  return moment.utc(`${mDate.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mmm').format(format)
}

export function validateAndFormatUserSubmittedDate(date) {
  const mDate = moment.isMoment(date) ? date : moment.utc(date, 'YYYY-MM-DD', true)
  return mDate.isValid() && mDate.format('YYYY-MM-DD')
}

export const formatHours = (hours) => {
  let time = ''
  if (parseInt(hours) < 9) time = '0'
  time += hours.toString() + ':00'
  return time
}

export const formatTimeToFloat = (time) => {
  if (time.includes(':30')) return parseInt(time.replace(':30'), 10) + 0.5
  return parseInt(time.replace(':00', ''), 10)
}

export function formatShortDay(day) {
  return day.toLowerCase().slice(0, 3).toLowerCase()
}
