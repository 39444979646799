import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import MomentPropTypes from 'react-moment-proptypes'

export const i18nextPropTypes = {
  t: PropTypes.func.isRequired,
  i18nLoadedAt: PropTypes.any,
  i18n: PropTypes.any,
  lng: PropTypes.string,
}

export const reduxPropTypes = {
  dispatch: PropTypes.func,
}

PropTypes.immutable = ImmutablePropTypes
PropTypes.moment = MomentPropTypes.momentObj

export default PropTypes
