import { createSelector } from 'reselect'
import moment from 'moment'
import { List } from 'immutable'
import {
  getEntities,
  getEntity,
  isFetching,
  isCreating,
  isDeleting,
  isUpdating,
  getFetchError,
  getUpdateError,
  getCreateError,
  getUpdateErrorRegex,
  getObjId,
  getMetadata,
  getEntityOrder,
} from './_utils'
import {
  getCurrentUser,
  getEventWithOverrides,
  getUser,
  getFile,
  getBooking,
  getOverride,
  getOverrideId,
} from 'redux/selectors'
import { emptyRequest } from 'redux/schemas'

export const getRequests = getEntities('requests')
export const getRequest = getEntity('requests')

export const getRequestFromToken = createSelector(
  getRequests,
  (_, token) => token,
  (requests, token) => requests.find((request) => request.invite_token === token, null, emptyRequest),
)

export const getRequestsForCurrentUser = createSelector(getRequests, getCurrentUser, (requests, currentUser) =>
  requests.filter((request) => request.user_id === currentUser.id),
)

export const getRequestForEventAndDate = createSelector(
  getRequestsForCurrentUser,
  (state, eventId, date) => ({ eventId, date }),
  (requests, { eventId, date }) =>
    requests
      .sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at))) // newest first
      .find(
        (request) =>
          request.event_id === eventId &&
          (date ? request.date === date : !request.date) &&
          !request.canceled_at &&
          !request.expired_at,
        null,
        emptyRequest, // default value
      ),
)

export const getRequestEvent = createSelector(
  getRequest,
  (state) => state,
  (request, state) => getEventWithOverrides(state, getObjId(request, 'event'), request.date),
)

export const getRequestEventOverrides = createSelector(
  getRequest,
  getRequestEvent,
  (state) => state,
  (request, event, state) => getOverride(state, getOverrideId(event.id, request.date)),
)

export const getRequestBookings = createSelector(
  getRequest,
  (state) => state,
  (request, state) => (request.bookings ? request.bookings.map((id) => getBooking(state, id)) : new List()),
)

export const getRequestGuest = createSelector(
  getRequest,
  (state) => state,
  (request, state) => getUser(state, getObjId(request, 'user')),
)

export const getRequestGuestAvatar = createSelector(
  getRequestGuest,
  (state) => state,
  (user, state) => getFile(state, getObjId(user, 'avatar')),
)

// metadatas getters
export const getRequestsMetadata = getMetadata('requests')
export const getRequestsList = createSelector(
  getEntityOrder('requests'),
  (state) => state,
  (ids, state) => ids.map((id) => getRequest(state, id)),
)

// notifiers
export const fetchingRequests = isFetching('/requests')
export const creatingRequest = isCreating('/requests')
export const fetchingRequest = (state, id) => isFetching(`/requests/${id}`)(state)
export const updatingRequest = (state, id) => isUpdating(`/requests/${id}`)(state)
export const deletingRequest = (state, id) => isDeleting(`/requests/${id}`)(state)

// errors
export const getFetchRequestsError = getFetchError('/requests')
export const getFetchRequestError = (state, id) => getFetchError(`/requests/${id}`)(state)
export const getUpdateRequestError = (state, id) => getUpdateError(`/requests/${id}`)(state)
export const getCreateRequestError = getCreateError('/requests')

export const getAnyUpdateRequestError = getUpdateErrorRegex(/\/requests/)
