import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { PaymentMethodSchema } from 'redux/schemas'

export const paymentMethodActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'paymentMethods' })

export const fetchPaymentMethods = (query) =>
  paymentMethodActions.fetch({
    url: '/users/profile/payment_methods',
    query,
    schema: { payment_methods: [PaymentMethodSchema] },
  })

export const createPaymentMethod = (payload, query) =>
  paymentMethodActions.create({
    url: '/users/profile/payment_methods',
    payload,
    query,
    schema: { payment_method: PaymentMethodSchema },
  })

export const deletePaymentMethod = (id, query) =>
  paymentMethodActions.delete({
    url: `/users/profile/payment_methods/${id}`,
    query,
    deleted: { paymentMethods: [String(id)] },
  })
