import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { WishlistSchema } from 'redux/schemas'

export const wishlistsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'wishlists' })

export function fetchWishlists(query) {
  return wishlistsActions.fetch({
    url: '/wishlists',
    query,
    schema: { wishlists: [WishlistSchema] },
  })
}

export const fetchWishlist = (id, query) =>
  wishlistsActions.fetch({
    url: `/wishlists/${id}`,
    query,
    schema: { wishlist: WishlistSchema },
  })

export function updateWishlist(id, payload) {
  return wishlistsActions.update({
    url: `/wishlists/${id}`,
    payload,
    schema: { wishlist: WishlistSchema },
  })
}

export function createWishlist(payload) {
  return wishlistsActions.create({
    url: '/wishlists',
    payload,
    schema: { wishlist: WishlistSchema },
  })
}

export const deleteWishlist = (id) =>
  wishlistsActions.delete({
    url: `/wishlists/${id}`,
    deleted: { wishlists: [id] },
  })
