import { screenApi } from 'apis'
import { ActionsCreator, basicHandlers, mergeMetadata } from '../lib'
import { BookingSchema, ReviewSchema } from 'redux/schemas'

export const bookingsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'bookings' })

export const fetchBookings = (query = { type: 'guest', status: 'demanded' }) =>
  bookingsActions.fetch({
    url: '/bookings',
    query,
    schema: { bookings: [BookingSchema] },
  })

export const fetchBooking = (id, query) =>
  bookingsActions.fetch({
    url: `/bookings/${id}`,
    query,
    schema: { booking: BookingSchema },
  })

export const updateBooking = (id, payload, query) =>
  bookingsActions.update({
    url: `/bookings/${id}`,
    payload,
    query,
    schema: { booking: BookingSchema },
  })

export const deleteBooking = (id, query) =>
  bookingsActions.delete({
    url: `/bookings/${id}`,
    query,
    schema: { booking: BookingSchema },
    deleted: { bookings: [id] },
  })

export const createBooking = (payload, query) =>
  bookingsActions.create({
    url: '/bookings',
    payload,
    query,
    schema: { booking: BookingSchema },
  })

export const notifyCoguest = (id, payload) =>
  bookingsActions.create({
    url: `/bookings/${id}/coguests`,
    payload,
    schema: {}, // hotfix: BE returns host price
  })

export const fetchBookingReview = (id) =>
  bookingsActions.fetch({
    url: `/bookings/${id}/reviews`,
    schema: { reviews: [ReviewSchema] },
  })

export const reportBookingIssue = (id, payload) =>
  bookingsActions.update({
    url: `/bookings/${id}/report_issue`,
    payload,
    schema: { booking: BookingSchema },
  })

export const bookingsCustomHandlers = {
  [bookingsActions.actionTypes.DELETED]: basicHandlers.mergeEntities,
  [bookingsActions.actionTypes.FETCHED]: mergeMetadata('bookings'),
}
