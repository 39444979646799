/**
 * Takes an object as input and remove all empty fields
 *
 * @param  {object} payload  - new object we want to compare to an existing one
 * @return {object}          - copy of payload without all the empty fields
 */
export function cleanPayloadEmptyFields(payload) {
  return Object.entries(payload).reduce(
    (acc, [key, value]) => (!!value || value === false ? Object.assign(acc, { [key]: value }) : acc),
    {},
  )
}
