import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { UserSchema } from './users'
import { BookingSchema } from './bookings'
import { RequestSchema } from './requests'
import { EventSchema } from './events'
import { ReplySchema } from './replies'

export const ConversationSchema = new schema.Entity('conversations', {
  bookings: [BookingSchema],
  request: RequestSchema,
  event: EventSchema,
  recipient: UserSchema,
  sender: UserSchema,
  last_reply: ReplySchema,
  recipient_first_reply: ReplySchema,
  sender_first_reply: ReplySchema,
  replies: [ReplySchema],
})

export const ConversationRecord = Record({
  id: undefined,

  recipient_id: undefined,
  sender_id: undefined,
  read: undefined,

  bookings: undefined,
  event: undefined,
  request: undefined,
  recipient: undefined,
  sender: undefined,
  last_reply: undefined,
  recipient_first_reply: undefined,
  sender_first_reply: undefined,
  replies: new List(),
  is_guest_replyable: undefined,

  created_at: undefined,
})

export const emptyConversation = new ConversationRecord()
