import moment from 'moment'
import { createSelector } from 'reselect'
import { List } from 'immutable'
import { emptyBooking, emptyReservation } from 'redux/schemas'
import {
  getEntities,
  getEntity,
  isFetching,
  isUpdating,
  getMetadata,
  getFetchError,
  getUpdateError,
  getEntityOrder,
  getObjId,
} from './_utils'
import {
  getBooking,
  getRequest,
  getUser,
  getEventWithOverrides,
  getReply,
  getOverride,
  getOverrideId,
  getReservationFromBooking,
  getReservationFromRequest,
} from 'redux/selectors'
import { getCurrentBooking } from 'helpers/booking'

// entity getters
export const getConversations = getEntities('conversations')
export const getConversation = getEntity('conversations')

export const getConversationBookings = createSelector(
  getConversation,
  (state) => state,
  (conversation, state) =>
    conversation.bookings && conversation.bookings.size > 0
      ? conversation.bookings.map((id) => getBooking(state, id))
      : new List([emptyBooking]),
)

export const getConversationRequest = createSelector(
  getConversation,
  (state) => state,
  (conversation, state) => getRequest(state, getObjId(conversation, 'request')),
)

export const getConversationBooking = createSelector(
  getConversationBookings,
  (bookings) => getCurrentBooking(bookings) || emptyBooking,
)

export const getConversationSender = createSelector(
  getConversation,
  (state) => state,
  (conversation, state) => getUser(state, getObjId(conversation, 'sender')),
)

export const getConversationRecipient = createSelector(
  getConversation,
  (state) => state,
  (conversation, state) => getUser(state, getObjId(conversation, 'recipient')),
)

export const getConversationReservation = createSelector(
  (state) => state,
  getConversationRequest,
  getConversationBooking,
  (state, request, booking) => {
    if (booking.id) return getReservationFromBooking(state, booking)
    else if (request.id) return getReservationFromRequest(state, request)
    else return emptyReservation
  },
)

export const getConversationEvent = createSelector(
  getConversation,
  getConversationReservation,
  (state) => state,
  (conversation, reservation, state) => getEventWithOverrides(state, getObjId(conversation, 'event'), reservation.date),
)

export const getConversationEventHost = createSelector(
  getConversationEvent,
  (state) => state,
  (event, state) => getUser(state, getObjId(event, 'user')),
)

export const getConversationEventOverrides = createSelector(
  getConversationReservation,
  getConversationEvent,
  (state) => state,
  (reservation, event, state) => getOverride(state, getOverrideId(event.id, reservation.date)),
)

export const getConversationReplies = createSelector(
  getConversation,
  (state) => state,
  (conversation, state) =>
    conversation.replies &&
    conversation.replies
      .map((id) => getReply(state, id))
      .sort((reply1, reply2) => moment.utc(reply1.created_at).diff(moment.utc(reply2.created_at))),
)

export const getConversationLastReply = createSelector(
  getConversation,
  (state) => state,
  (conversation, state) => getReply(state, getObjId(conversation, 'last_reply')),
)

// metadatas getters
export const getConversationsMetadata = getMetadata('conversations')
export const getConversationsList = createSelector(
  getEntityOrder('conversations'),
  (state) => state,
  (ids, state) => ids.map((id) => getConversation(state, id)),
)

// errors
export const getFetchConversationsError = getFetchError('/conversations')
export const getUpdateConversationError = (state, id) => getUpdateError(`/conversations/${id}`)(state)

// notifiers
export const fetchingConversation = (state, id) => isFetching(`/conversations/${id}`)(state)
export const updatingConversation = (state, id) => isUpdating(`/conversations/${id}`)(state)
export const fetchingConversations = isFetching('/conversations')
