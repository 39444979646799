import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { StripeAccountSchema, StripeCountrySchema } from 'redux/schemas'

export const stripeAccountsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'stripeAccounts' })

export const fetchStripeAccount = () =>
  stripeAccountsActions.fetch({
    url: '/users/profile/account/stripe',
    schema: { stripeAccount: StripeAccountSchema },
  })

export const fetchOrCreateStripeAccount = () =>
  stripeAccountsActions.create({
    url: '/users/profile/account/stripe',
    schema: { stripeAccount: StripeAccountSchema },
  })

export const fetchStripeCountries = ({ countryIso = 'FR' }) =>
  stripeAccountsActions.fetch({
    url: `/countries/${countryIso.toUpperCase()}/stripe`,
    schema: StripeCountrySchema,
  })
