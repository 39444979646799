import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { PricingSchema } from 'redux/schemas'
import { validateAndFormatUserSubmittedDate } from 'helpers/date'

export const pricingsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'pricings' })

export function fetchPricing(id, { seats = 1 || '1', promoCode = undefined, date, ...query } = {}) {
  date = validateAndFormatUserSubmittedDate(date)
  return pricingsActions.fetch({
    url: `/events/${id}/pricing`,
    query: {
      seats,
      promo_code: promoCode,
      date: date || '',
      ...query,
    },
    schema: PricingSchema,
  })
}
