import { Map, List } from 'immutable'
import { createSelector } from 'reselect'
import { emptyByEntities } from 'redux/schemas'
import { isNil, isObject } from 'lodash'

// Used as "common reference" for an empty object (memory optimization + equality check optimization)
const emptyList = new List()
const emptyMap = new Map()

// utility to get the id based on the entity shape
export const getObjId = (obj, entityName = '') => {
  switch (true) {
    case !isNil(obj[`${entityName}_id`]):
      return String(obj[`${entityName}_id`])
    case isObject(obj[entityName]):
      return String(obj[entityName].id)
    default:
      return String(obj[entityName])
  }
}

/**
 * Retrieve the redux-entities substate tree
 * When used within custom handlers, the state slice given may already be the entities state, so we need to check what we're given
 * @param  {Object|Map} state  can be the wole state or the entities subset tree
 * @return {Map}               the substate tree or an empty Map
 */
export const getEntitiesState = (state) => {
  if (Map.isMap(state) && state.has('entities')) return state
  return emptyMap
}

// entities
export const getEntities = (name) =>
  createSelector(getEntitiesState, (entitiesState) => entitiesState.getIn(['entities', name], emptyMap))

// loading notifiers
export const getLoading = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('loading'))
export const isFetching = (url) => createSelector(getLoading, (loading) => loading.has(`GET ${url}`))
export const isUpdating = (url) => createSelector(getLoading, (loading) => loading.has(`PUT ${url}`))
export const isDeleting = (url) => createSelector(getLoading, (loading) => loading.has(`DELETE ${url}`))
export const isPatching = (url) => createSelector(getLoading, (loading) => loading.has(`PATCH ${url}`))
export const isCreating = (url) => createSelector(getLoading, (loading) => loading.has(`POST ${url}`))
export const isFetchingRegex = (regex) =>
  createSelector(getLoading, (loading) => !!loading.find((url) => url.startsWith('GET') && regex.test(url)))
export const isCreatingRegex = (regex) =>
  createSelector(getLoading, (loading) => !!loading.find((url) => url.startsWith('POST') && regex.test(url)))
export const isUpdatingRegex = (regex) =>
  createSelector(getLoading, (loading) => !!loading.find((url) => url.startsWith('PUT') && regex.test(url)))

// errors
export const getErrors = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('errors'))
export const getFetchError = (url) => createSelector(getErrors, (errors) => errors.get(`GET ${url}`))
export const getFetchErrorRegex = (regex) =>
  createSelector(getErrors, (errors) => errors.find((v, url) => url.startsWith('GET') && regex.test(url)))
export const getUpdateError = (url) => createSelector(getErrors, (errors) => errors.get(`PUT ${url}`))
export const getCreateError = (url) => createSelector(getErrors, (errors) => errors.get(`POST ${url}`))
export const getDeleteError = (url) => createSelector(getErrors, (errors) => errors.get(`DELETE ${url}`))
export const getPatchError = (url) => createSelector(getErrors, (errors) => errors.get(`PATCH ${url}`))
export const getCreateErrorRegex = (regex) =>
  createSelector(getErrors, (errors) => errors.find((v, url) => url.startsWith('POST') && regex.test(url)))
export const getUpdateErrorRegex = (regex) =>
  createSelector(getErrors, (errors) => errors.find((v, url) => url.startsWith('PUT') && regex.test(url)))

// metadatas
export const getMetadatas = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('metadata'))
export const getMetadata = (name) => createSelector(getMetadatas, (metadatas) => metadatas.get(name, emptyMap))

// entity
export const getEntity = (name) =>
  createSelector(
    getEntities(name),
    (_, id) => (isObject(id) ? id.id : id), // if id is an object it's legacy.
    (mapOfEntities, id) => mapOfEntities.get(String(id), emptyByEntities[name]),
  )

// metadata
export const getEntityCount = (name) => createSelector(getEntities(name), (entities) => entities.size)
export const getEntityOrder = (name) => createSelector(getMetadata(name), (data) => data.get('order', emptyList))
export const getEntityTotal = (name) => createSelector(getMetadata(name), (data) => data.get('count', 0))
export const getEntityOffset = (name) => createSelector(getMetadata(name), (data) => data.get('offset', 0))
export const getEntitySize = (name) => createSelector(getMetadata(name), (data) => data.get('size', 0))
