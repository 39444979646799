import { schema } from 'normalizr'
import { Record } from 'immutable'

export const OverrideSchema = new schema.Entity(
  'overrides',
  {},
  {
    idAttribute: (override) => (override.event_id ? `${override.event_id}-${override.date}` : undefined),
    processStrategy: (entity) => {
      // Backend sends always booking_deadline and cancellation_deadline, so we duplicate the value
      //     - as a host, correctly compare with defaults values as in the calendar
      //     - as a guest, simply getting the value without issues during booking, summary, etc.
      const isPrivate = entity.privatized_at || entity.privatized_by
      return {
        ...entity,
        pe_booking_deadline: isPrivate ? entity.booking_deadline : undefined,
        pe_cancellation_deadline: isPrivate ? entity.cancellation_deadline : undefined,
      }
    },
  },
)
export const OverrideRecord = Record({
  date: undefined,
  event_id: undefined,
  max_seats: undefined,
  min_seats: undefined,
  begins_at: undefined,
  ends_at: undefined,
  event_price: undefined,
  price: undefined,
  booking_deadline: undefined,
  cancellation_deadline: undefined,
  pe_booking_deadline: undefined,
  pe_cancellation_deadline: undefined,
  instant_booking: undefined,
  privatized_by: undefined,
  privatized_at: undefined,
})

export const emptyOverride = new OverrideRecord()
