import { createSelector } from 'reselect'
import { Map } from 'immutable'
import { getEntities, getEntity, getObjId, isFetching, getFetchError } from './_utils'
import { getCurrency } from 'redux/selectors'
export const getPricings = getEntities('pricings')

const getCurrencyIdIn = (pricing, key) => getObjId((pricing[key] || new Map()).toJS(), 'currency')

export const getPricing = createSelector(
  getEntity('pricings'),
  (state) => state,
  (pricing, state) =>
    pricing
      .setIn(['payee', 'currency'], getCurrency(state, getCurrencyIdIn(pricing, 'payee')))
      .setIn(['discount', 'currency'], getCurrency(state, getCurrencyIdIn(pricing, 'discount')))
      .setIn(['fees', 'currency'], getCurrency(state, getCurrencyIdIn(pricing, 'fees')))
      .setIn(['payer', 'currency'], getCurrency(state, getCurrencyIdIn(pricing, 'payer'))),
)

export const fetchingPricing = (state, eventId) => isFetching(`/events/${eventId}/pricing`)(state)
export const getFetchPricingError = (state, eventId) => getFetchError(`/events/${eventId}/pricing`)(state)
