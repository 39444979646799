// WE CANNOT RELY ON IMPORT FROM ./index.js FOR THIS DUE TO CIRCULAR DEPENDENCIES
import { AccountSchema, AccountRecord, emptyAccount } from './accounts'
import { AlcoholSchema, AlcoholRecord, emptyAlcohol } from './alcohols'
import { AmenitySchema, AmenityRecord, emptyAmenity } from './amenities'
import { BankAccountSchema, BankAccountRecord, emptyBankAccount } from './bankAccounts'
import { BookingSchema, BookingRecord, emptyBooking } from './bookings'
import { CampaignSchema, CampaignRecord, emptyCampaign } from './campaigns'
import { ConversationSchema, ConversationRecord, emptyConversation } from './conversations'
import { CouponSchema, CouponRecord, emptyCoupon } from './coupons'
import { CriterionSchema, CriterionRecord, emptyCriterion } from './criteria'
import { CurrencySchema, CurrencyRecord, emptyCurrency } from './currencies'
import { CurrentUserSchema, CurrentUserRecord, emptyCurrentUser } from './currentUsers'
import { DestinationSchema, DestinationRecord, emptyDestination } from './destinations'
import { DietSchema, DietRecord, emptyDiet } from './diets'
import { EventSchema, EventRecord, emptyEvent } from './events'
import { FileSchema, FileRecord, emptyFile } from './files'
import { FoodSchema, FoodRecord, emptyFood } from './foods'
import { InviteSchema, InviteRecord, emptyInvite } from './invites'
import { HostApplicationSchema, HostApplicationRecord, emptyHostApplication } from './hostApplications'
import { LanguageSchema, LanguageRecord, emptyLanguage } from './languages'
import { IPGeolocationSchema, IPGeolocationRecord, emptyIPGeolocation } from './IPGeolocation'
import { NoteSchema, NoteRecord, emptyNote } from './notes'
import { NotificationSchema, SubjectRecord, emptySubject, NotificationRecord, emptyNotification } from './notifications'
import { OrganizationSchema, OrganizationRecord, emptyOrganization } from './organizations'
import { OverrideSchema, OverrideRecord, emptyOverride } from './overrides'
import { PartnerRecord, PartnerSchema, emptyPartner } from './partners'
import { PaymentSchema, PaymentRecord, emptyPayment } from './payments'
import { PaymentMethodSchema, PaymentMethodRecord, emptyPaymentMethod } from './paymentMethods'
import { PayoutSchema, PayoutRecord, emptyPayout } from './payouts'
import { PlaceSchema, PlaceRecord, emptyPlace } from './places'
import { PlaylistSchema, PlaylistRecord, emptyPlaylist } from './playlists'
import { PricingSchema, PricingRecord, emptyPricing } from './pricings'
import { ReferralWalletSchema, ReferralWalletRecord, emptyReferralWallet } from './referralWallets'
import { ReplySchema, ReplyRecord, emptyReply } from './replies'
import { RequestSchema, RequestRecord, emptyRequest } from './requests'
import { ReviewSchema, ReviewRecord, emptyReview } from './reviews'
import { ScheduleSchema, ScheduleRecord, emptySchedule } from './schedules'
import { StripeAccountSchema, StripeAccountRecord, emptyStripeAccount } from './stripeAccounts'
import { StripeCountrySchema, StripeCountryRecord, emptyStripeCountry } from './stripeCountries'
import { TripAdvisorUserSchema, TripAdvisorUserRecord, emptyTripAdvisorUser } from './tripAdvisorUsers'
import { UserSchema, UserRecord, emptyUser } from './users'
import { WishlistSchema, WishlistRecord, emptyWishlist } from './wishlists'
// circular definitions
EventSchema.define({ user: UserSchema })
UserSchema.define({ events: [EventSchema] })
StripeAccountSchema.define({ bankAccounts: [BankAccountSchema] })
BookingSchema.define({ request: RequestSchema })
RequestSchema.define({ bookings: [BookingSchema] })

// Entity mapping
export const schemasByEntities = {
  accounts: AccountSchema,
  alcohols: AlcoholSchema,
  amenities: AmenitySchema,
  bankAccounts: BankAccountSchema,
  bookings: BookingSchema,
  campaigns: CampaignSchema,
  conversations: ConversationSchema,
  coupons: CouponSchema,
  criteria: CriterionSchema,
  currencies: CurrencySchema,
  currentUsers: CurrentUserSchema,
  destinations: DestinationSchema,
  diets: DietSchema,
  events: EventSchema,
  files: FileSchema,
  foods: FoodSchema,
  invites: InviteSchema,
  hostApplications: HostApplicationSchema,
  languages: LanguageSchema,
  IPGeolocation: IPGeolocationSchema,
  notes: NoteSchema,
  notifications: NotificationSchema,
  organizations: OrganizationSchema,
  overrides: OverrideSchema,
  partners: PartnerSchema,
  paymentMethods: PaymentMethodSchema,
  payments: PaymentSchema,
  payouts: PayoutSchema,
  places: PlaceSchema,
  playlists: PlaylistSchema,
  pricings: PricingSchema,
  referralWallets: ReferralWalletSchema,
  replies: ReplySchema,
  requests: RequestSchema,
  reviews: ReviewSchema,
  schedules: ScheduleSchema,
  stripeAccounts: StripeAccountSchema,
  stripeCountries: StripeCountrySchema,
  tripAdvisorUsers: TripAdvisorUserSchema,
  users: UserSchema,
  wishlists: WishlistSchema,
}

export const recordsByEntities = {
  accounts: AccountRecord,
  alcohols: AlcoholRecord,
  amenities: AmenityRecord,
  bankAccounts: BankAccountRecord,
  bookings: BookingRecord,
  campaigns: CampaignRecord,
  conversations: ConversationRecord,
  coupons: CouponRecord,
  criteria: CriterionRecord,
  currencies: CurrencyRecord,
  currentUsers: CurrentUserRecord,
  destinations: DestinationRecord,
  diets: DietRecord,
  events: EventRecord,
  files: FileRecord,
  foods: FoodRecord,
  invites: InviteRecord,
  hostApplications: HostApplicationRecord,
  languages: LanguageRecord,
  IPGeolocation: IPGeolocationRecord,
  notes: NoteRecord,
  notifications: NotificationRecord,
  organizations: OrganizationRecord,
  overrides: OverrideRecord,
  partners: PartnerRecord,
  paymentMethods: PaymentMethodRecord,
  payments: PaymentRecord,
  payouts: PayoutRecord,
  places: PlaceRecord,
  playlists: PlaylistRecord,
  pricings: PricingRecord,
  referralWallets: ReferralWalletRecord,
  replies: ReplyRecord,
  requests: RequestRecord,
  reviews: ReviewRecord,
  schedules: ScheduleRecord,
  stripeAccounts: StripeAccountRecord,
  stripeCountries: StripeCountryRecord,
  subject: SubjectRecord,
  tripAdvisorUsers: TripAdvisorUserRecord,
  users: UserRecord,
  wishlists: WishlistRecord,
}

export const emptyByEntities = {
  accounts: emptyAccount,
  alcohols: emptyAlcohol,
  amenities: emptyAmenity,
  bankAccounts: emptyBankAccount,
  bookings: emptyBooking,
  campaigns: emptyCampaign,
  conversations: emptyConversation,
  coupons: emptyCoupon,
  criteria: emptyCriterion,
  currencies: emptyCurrency,
  currentUsers: emptyCurrentUser,
  destinations: emptyDestination,
  diets: emptyDiet,
  events: emptyEvent,
  files: emptyFile,
  foods: emptyFood,
  invites: emptyInvite,
  hostApplications: emptyHostApplication,
  languages: emptyLanguage,
  IPGeolocation: emptyIPGeolocation,
  notes: emptyNote,
  notifications: emptyNotification,
  organizations: emptyOrganization,
  overrides: emptyOverride,
  partners: emptyPartner,
  paymentMethods: emptyPaymentMethod,
  payments: emptyPayment,
  payouts: emptyPayout,
  places: emptyPlace,
  playlists: emptyPlaylist,
  pricings: emptyPricing,
  referralWallets: emptyReferralWallet,
  replies: emptyReply,
  requests: emptyRequest,
  reviews: emptyReview,
  schedules: emptySchedule,
  stripeAccounts: emptyStripeAccount,
  stripeCountries: emptyStripeCountry,
  subject: emptySubject,
  tripAdvisorUsers: emptyTripAdvisorUser,
  users: emptyUser,
  wishlists: emptyWishlist,
}
