import { screenApi } from 'apis'
import { ActionsCreator, mergeMetadata } from '../lib'
import { PayoutSchema } from 'redux/schemas'

export const payoutsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'payouts' })

export const fetchPayouts = (query, config) =>
  payoutsActions.fetch({
    url: '/payouts',
    query,
    schema: { payouts: [PayoutSchema] },
    ...config,
  })

export const fetchPayout = (id, query) => {
  return payoutsActions.fetch({
    url: `/payouts/${id}`,
    query,
    schema: { payout: PayoutSchema },
  })
}

export const payoutsCustomHandlers = {
  [payoutsActions.actionTypes.FETCHED]: mergeMetadata('payouts'),
}
