import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { PlaylistSchema } from 'redux/schemas'

export const playlistsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'playlists' })

export const fetchPlaylists = (query, config) =>
  playlistsActions.fetch({
    url: '/playlists',
    query,
    schema: { playlists: [PlaylistSchema] },
    ...config,
  })

export const fetchPlaylist = (id, query) =>
  playlistsActions.fetch({
    url: `/playlists/${id}`,
    query,
    schema: { playlist: PlaylistSchema },
  })
