import { schema } from 'normalizr'
import { Record, List, Map } from 'immutable'

export const HostApplicationSchema = new schema.Entity(
  'hostApplications',
  {},
  {
    idAttribute: (entity) => entity._id || entity.id || 1,
    processStrategy: (entity) => ({ ...entity, id: entity._id }),
  },
)

export const HostApplicationRecord = Record({
  id: undefined,
  host: Map({
    host_name: undefined,
    spoken_languages: List([{}]),
    culinary_experience: List([{}]),
    motivation: undefined,
    online_profiles: Map(),
    assets: List(),
    description: undefined,
    interested_in_experience: Map(),
    availabilities: undefined,
  }),
  sample_experience: Map({
    experience_type: undefined,
    place: Map(),
    description: Map(),
    min_seats: undefined,
    max_seats: undefined,
    guest_type: List(),
    user_video: Map(),
    currency_iso3: undefined,
    min_price: undefined,
    max_price: undefined,
    policy: undefined,
  }),
  metadata: Map({
    event_id: undefined,
    status: '',
    submitted_at: undefined,
  }),
})

export const emptyHostApplication = new HostApplicationRecord()
