import { schema } from 'normalizr'
import { Record, List } from 'immutable'

import { PaymentSchema } from './payments'
import { CurrencySchema } from './currencies'

export const PayoutSchema = new schema.Entity('payouts', {
  currency: CurrencySchema,
  payments: [PaymentSchema],
})

export const PayoutRecord = Record({
  id: undefined,
  amount: undefined,
  currency: undefined,
  gateway: undefined,
  paid_at: undefined,
  sent_at: undefined,
  status: undefined,
  payments: new List(),
})

export const emptyPayout = new PayoutRecord()
