import { createSelector } from 'reselect'
import { getEntitiesState } from './_utils'
import { Map } from 'immutable'

export const getRouterState = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('router') || Map())

export const getRouterStateAsJS = createSelector(getRouterState, (state) => state.toJS())

export const getLocation = createSelector(getRouterState, (state) => state.get('locationBeforeTransitions') || {})

export const getPrevLocation = createSelector(getRouterState, (state) => state.get('prevLocation') || {})

export const getRouterPrevPage = createSelector(getRouterState, (state) => state.get('prevPage') || {})
