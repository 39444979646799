import { screenApi } from 'apis'
import { ActionsCreator, clearHandler } from '../lib'
import { BankAccountSchema } from 'redux/schemas'

export const paymentsActions = {
  CLEAR_BANKS: '@@payments/CLEAR_BANKS',
}

export const paymentActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'payments' })

export const createBankAccount = (payload) =>
  paymentActions.create({
    url: '/users/profile/banks',
    payload,
    schema: { bank: BankAccountSchema },
  })

export const fetchBankAccounts = () =>
  paymentActions.fetch({
    url: '/users/profile/banks',
    schema: { banks: [BankAccountSchema] },
  })

export const deleteBankAccount = (id) =>
  paymentActions.delete({
    url: `/users/profile/banks/${id}`,
    deleted: { bankAccounts: [String(id)] },
  })

export const clearBanks = () => (dispatch, getState) => dispatch({ type: paymentsActions.CLEAR_BANKS })

export const paymentsCustomHandlers = {
  [paymentsActions.CLEAR_BANKS]: clearHandler('bankAccounts'),
}
