import { createSelector } from 'reselect'
import { getEntities, isFetching } from './_utils'
import { emptyTripAdvisorUser } from 'redux/schemas'
import { getUser } from './users'

export const getTripAdvisorUsers = getEntities('tripAdvisorUsers')

export const getTripAdvisorIdFromUser = createSelector(
  getUser,
  (user) => user.properties && user.properties.get('tripadvisor_id'),
)

export const getTripAdvisorUser = createSelector(getTripAdvisorUsers, getTripAdvisorIdFromUser, (taUsers, taId) =>
  taId ? taUsers.get(String(taId), emptyTripAdvisorUser) : null,
)

export const fetchingTripAdvisorUser = isFetching('/tar/location')

export const getTripAdvisorTopRankingText = createSelector(getTripAdvisorUser, (tripAdvisorUser) => {
  if (!tripAdvisorUser || !tripAdvisorUser.ranking_data) return null
  const ranking = parseInt(tripAdvisorUser.ranking_data.get('ranking'))
  const total = parseInt(tripAdvisorUser.ranking_data.get('ranking_out_of'))
  const isTop10Percent = total && ranking / total <= 0.1
  return isTop10Percent ? tripAdvisorUser.ranking_data.get('ranking_string') : null
})

export const getTripAdvisorAwardImage = createSelector(getTripAdvisorUser, (tripAdvisorUser) => {
  if (!tripAdvisorUser) return null
  // There might several types of awards here according to the documentation but they are
  // identified by translated name, which is not reliable, so return the most recent one
  return tripAdvisorUser
    .get('awards')
    .sortBy((awards) => awards.get('year'))
    .getIn([0, 'images', 'large'], undefined)
})

export const getTripAdvisorUserReviewUrl = createSelector(getTripAdvisorUser, (tripAdvisorUser) => {
  return tripAdvisorUser ? tripAdvisorUser.get('write_review') : null
})
