import { createSelector } from 'reselect'
import { getEntities, getEntity } from './_utils'
import { getBooking } from 'redux/selectors'

export const getCoupons = getEntities('coupons')
export const getCoupon = getEntity('coupons')

export const getCouponBooking = createSelector(
  getCoupon,
  (state) => state,
  (coupon, state) => getBooking(state, coupon.booking),
)
