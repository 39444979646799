import { screenApi } from 'apis'
import { ActionsCreator, basicHandlers } from '../lib'
import { EventSchema, OverrideSchema } from 'redux/schemas'
import { validateAndFormatUserSubmittedDate } from 'helpers/date'
import { normalize } from 'normalizr'
import { chainReducers } from '@vizeat/redux-entities/es6/utils'

export const eventsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'events' })
const EVENTS_AVAILABILITIES_UPDATING = 'EVENTS_AVAILABILITIES_UPDATING'
const EVENTS_AVAILABILITIES_UPDATED = 'EVENTS_AVAILABILITIES_UPDATED'
const EVENTS_AVAILABILITIES_UPDATE_ERROR = 'EVENTS_AVAILABILITIES_UPDATE_ERROR'

export const fetchEvents = (query, config) =>
  eventsActions.fetch({
    url: '/events',
    query,
    schema: { events: [EventSchema] },
    ...config,
  })

export const fetchEvent = (id, date) => {
  date = date === 'next' ? date : validateAndFormatUserSubmittedDate(date)
  const query = date ? { date } : {}
  return eventsActions.fetch({
    url: `/events/${id}`,
    query,
    schema: { event: EventSchema },
  })
}

export const createEvent = (payload) =>
  eventsActions.create({
    url: '/events',
    payload,
    schema: { event: EventSchema },
  })

export const updateEvent = (id, payload) =>
  eventsActions.update({
    url: `/events/${id}`,
    payload,
    schema: { event: EventSchema },
  })

export const deleteEvent = (id, query, config) =>
  eventsActions.delete({
    url: `/events/${id}`,
    query,
    schema: { event: EventSchema },
    ...config,
  })

export const updateScheduleEventByDate = (id, date, payload) =>
  eventsActions.update({
    url: `/events/${id}/planning/${date.format('YYYY-MM-DD')}`,
    payload: {
      ...payload,
      open: true,
    },
    schema: { overrides: OverrideSchema },
  })

export const shareEvent = (id, payload) =>
  eventsActions.create({
    url: `/events/${id}/share`,
    payload,
    schema: { event: EventSchema },
  })

export const updateEventsAvailabilities =
  ({ eventIds, availabilities, force }) =>
  async (dispatch, getState) => {
    const eventsEntity = getState().getIn(['entities', 'events'])
    const params = {
      event_ids: eventIds,
      default_availabilities: availabilities,
      force_apply_default_availabilities: force,
    }

    const payload = { url: '/events/availabilities', method: 'POST', params }

    dispatch({
      type: EVENTS_AVAILABILITIES_UPDATING,
      payload,
    })

    try {
      await screenApi.post('/events/availabilities', params)

      return eventIds.map((id) =>
        dispatch({
          type: EVENTS_AVAILABILITIES_UPDATED,
          payload: {
            ...payload,
            data: normalize(
              {
                ...eventsEntity.get(String(id)).toJS(),
                default_availabilities: availabilities,
              },
              EventSchema,
            ),
          },
        }),
      )
    } catch (e) {
      return dispatch({
        type: EVENTS_AVAILABILITIES_UPDATE_ERROR,
        payload: { ...payload, error: e },
      })
    }
  }

export const eventsCustomHandlers = {
  [eventsActions.actionTypes.DELETED]: basicHandlers.mergeEntities,
  EVENTS_AVAILABILITIES_UPDATING: chainReducers(basicHandlers.removeError, basicHandlers.setLoadingUrl),
  EVENTS_AVAILABILITIES_UPDATED: chainReducers(basicHandlers.removeLoadingUrl, basicHandlers.mergeEntities),
  EVENTS_AVAILABILITIES_UPDATE_ERROR: chainReducers(basicHandlers.removeLoadingUrl, basicHandlers.mergeError),
}
