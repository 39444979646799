import { getEntities, getEntity, isFetching, getObjId } from './_utils'
import { createSelector } from 'reselect'
import { getBooking, getBookingEvent, getBookingGuest, getCurrency } from 'redux/selectors'

export const fetchingPayments = isFetching('/payments')
export const fetchingPayment = (state, id) => isFetching(`/payments/${id}`)(state)

export const getPayments = getEntities('payments')

export const getPayment = getEntity('payments')

export const getPaymentBooking = createSelector(
  getPayment,
  (state) => state,
  (payment, state) => getBooking(state, getObjId(payment, 'booking')),
)

export const getPaymentBookingEvent = createSelector(
  getPayment,
  (state) => state,
  (payment, state) => getBookingEvent(state, getObjId(payment, 'booking')),
)

export const getPaymentBookingGuest = createSelector(
  getPayment,
  (state) => state,
  (payment, state) => getBookingGuest(state, getObjId(payment, 'booking')),
)

export const getPaymentPayeeCurrency = createSelector(
  getPayment,
  (state) => state,
  (payment, state) => getCurrency(state, getObjId({ currency_id: payment.payee_currency }, 'currency')),
)
