import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import { konstants } from '@vizeat/helpers'
import { screenApi } from 'apis'
import { loadCookie, COOKIE_KEYS } from 'helpers/cookies'

const initLlocale = loadCookie(COOKIE_KEYS.EW_LANGUAGE) || 'en'

// Set language for momentjs and axios queries
screenApi.setHeader('X-Language', initLlocale)

const options = (locale, whitelist = konstants.LANGUAGES) => ({
  lng: locale,
  whitelist,
  fallbackLng: 'en',
  nsSeparator: '~',
  keySeparator: '::',

  ns: ['common'],
  defaultNS: 'common',
  keySpaces: false,
  interpolation: {
    escape: false,
    escapeValue: false,
  },
  returnEmptyString: false,
  react: {
    useSuspense: false,
  },
  debug: __DEBUG_I18N__,
})

export function initI18next(locale, additionalOptions = {}, whitelist) {
  i18next.use(XHR).init({
    ...options(locale, whitelist),
    ...additionalOptions,
  })
  return i18next
}

const i18n = initI18next(initLlocale, {
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: function (value, format, lng) {
      if (format === 'list') {
        return new Intl.ListFormat(lng, {
          style: 'long',
          type: 'conjunction',
        }).format(value)
      }
    },
  },
})

// Ensure Intl exists in global context
if (!global.Intl) {
  global.Intl = require('intl')
}

export default i18n
