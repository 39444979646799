import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetchingRegex, getFetchError } from './_utils'
import { getCurrentUserBillingCountryIso } from 'redux/selectors'

// getters
export const getStripeCountries = getEntities('stripeCountries')
export const getStripeCountry = getEntity('stripeCountries')

export const getStripeCountryForCurrentUser = createSelector(
  getCurrentUserBillingCountryIso,
  (state) => state,
  (countryIso, state) => getStripeCountry(state, countryIso),
)

// notifiers
export const fetchingStripeCountry = isFetchingRegex(/\/countries\/.*\/stripe/)
export const getFetchStripeCountryError = (state, id) => getFetchError(`/countries/${id}/stripe`)(state)
