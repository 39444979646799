import moment from 'moment'

const today = moment.utc()
export const baseViewUrl = '/dashboard/planning'

export const routes = {
  day: (date, eventId, location = {}) => ({
    pathname: `${baseViewUrl}/${moment.utc(date).format('YYYY/M/D')}`,
    query: { eventId },
    ...location,
  }),
  dayWithEvent: (date, eventId, location = {}) => ({
    pathname: `${baseViewUrl}/${moment.utc(date).format('YYYY/M/D')}/event/${eventId}`,
    ...location,
  }),
  month: (date = today, eventId = 'all', location = {}) => ({
    pathname: `${baseViewUrl}/${moment.utc(date).format('YYYY/M')}`,
    query: { eventId },
    ...location,
  }),
}
