import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { RequestSchema } from './requests'
import { BookingSchema } from './bookings'

export const InviteSchema = new schema.Entity(
  'invites',
  {
    request: RequestSchema,
    // schedule: ScheduleSchema, ← schedule are now managed in actions/invites
    bookings: [BookingSchema],
  },
  { idAttribute: 'invite_token' },
)

export const InviteRecord = new Record({
  invite_token: undefined,
  date: undefined,
  event_id: undefined,
  request_id: undefined,
  schedule: undefined,
  bookings: new List(),
})

export const emptyInvite = new InviteRecord()
