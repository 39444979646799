import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { CouponSchema } from './coupons'
import { CurrencySchema } from './currencies'

export const CampaignSchema = new schema.Entity('campaigns', {
  coupons: [CouponSchema],
  currency: CurrencySchema,
})

export const CampaignRecord = Record({
  id: undefined,
  category: '',
  code: '',
  coupons: new List(),
  currency: undefined,
  discount: undefined,
  min_price_limit: undefined,
  user: undefined,
})

export const emptyCampaign = new CampaignRecord()
