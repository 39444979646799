import React from 'react'
import PropTypes from './proptypes'
import Loadable from 'react-loadable'

const reload = (retry) => {
  if (typeof window !== 'undefined' && window.location.reload) {
    return window.location.reload(true) // force reload from server with SSR & ignore cache
  } else if (typeof retry === 'function') {
    return retry()
  }
}

const Loading = React.memo(function Loading(props) {
  return props.error ? (
    <>
      <strong>Error: {props.error.message}</strong>
      {process.env.NODE_ENV !== 'production' && <pre>{props.error.stack}</pre>}
      <button onClick={() => reload(props.retry)}>Retry</button>
    </>
  ) : props.timedOut ? (
    <div>
      The request timed out, please <button onClick={() => reload(props.retry)}>Retry</button>
    </div>
  ) : props.pastDelay ? (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute' }}>Loading...</div>
    </div>
  ) : null
})

Loading.displayName = 'Loading'
Loading.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    stack: PropTypes.string,
  }),
  timedOut: PropTypes.bool.isRequired,
  pastDelay: PropTypes.bool.isRequired,
  retry: PropTypes.func.isRequired,
}

Loading.defaultProps = {
  error: undefined,
}

/**
 * Loadable Wrapper
 * @param {Function} loader       a loader function, usually: () => import('path/to/component')
 * @param {String}   path         the path/to/component string, repeated, as it cannot be infered otherwise
 * @param {String}   namedExport  name of the component if it is a named export (eg: not a default export)
 */
export const LoadableWrapper = (loader, path, namedExport = undefined) =>
  Loadable({
    loader, // you cannot have import(path) here because webpack cannot manage it
    render(loaded, props) {
      const Component = namedExport ? loaded[namedExport] : loaded.default
      return <Component {...props} />
    },
    modules: [path], // required for SSR
    webpack: () => [require.resolveWeak(path)], // required for SSR
    loading: Loading,
    delay: 700, // wait 700ms before showing the Loading component
    timeout: 10000, // wait 10 sec before showing a timeout
  })
