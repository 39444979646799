import { createSelector } from 'reselect'
import { getEntities, getEntity } from './_utils'
import { emptyLanguage } from 'redux/schemas'
export const getLanguages = getEntities('languages')
export const getLanguage = getEntity('languages')

export const getSortedLanguages = createSelector(getLanguages, (languages) =>
  languages.toList().sortBy(
    (l) => l.title,
    (str1, str2) => str1.localeCompare(str2),
  ),
)

export const getLanguageByLocale = createSelector(
  getLanguages,
  (_, locale) => locale,
  (languages, locale) => languages.find((language) => language.locale === locale, null, emptyLanguage),
)
