import { LoadableWrapper } from 'helpers/loadable'

export const AppLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "AppLayout" */ './AppLayout'),
  './AppLayout',
  'AppLayout',
)

export const DashboardLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "DashboardLayout" */ './DashboardLayout'),
  './DashboardLayout',
  'DashboardLayout',
)

export const DefaultLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "DefaultLayout" */ './DefaultLayout'),
  './DefaultLayout',
  'DefaultLayout',
)

export const ExpandableLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "ExpandableLayout" */ './ExpandableLayout'),
  './ExpandableLayout',
  'ExpandableLayout',
)

export const FixedPanelLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "FixedPanelLayout" */ './FixedPanelLayout'),
  './FixedPanelLayout',
  'FixedPanelLayout',
)

export const SidebarLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "SidebarLayout" */ './SidebarLayout'),
  './SidebarLayout',
  'SidebarLayout',
)

export const TabPageLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "TabPageLayout" */ './TabPageLayout'),
  './TabPageLayout',
  'TabPageLayout',
)

export const TabsLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "TabsLayout" */ './TabsLayout'),
  './TabsLayout',
  'TabsLayout',
)

export const TabsPane = LoadableWrapper(
  () => import(/* webpackChunkName: "TabsLayout" */ './TabsLayout'),
  './TabsLayout',
  'TabsPane',
)

export const TwoColumnLayout = LoadableWrapper(
  () => import(/* webpackChunkName: "TwoColumnLayout" */ './TwoColumnLayout'),
  './TwoColumnLayout',
  'TwoColumnLayout',
)
