import { List, Map } from 'immutable'
import { range, times } from 'lodash'
import { formatTimeToFloat } from 'helpers/date'

export const EVENT_STATUS_PUBLISHED = 'approved'
export const EVENT_STATUS_TO_REVIEW = 'to_review'
export const EVENT_STATUS_DRAFT = 'draft'
export const EVENT_STATUS_REJECTED = 'rejected'

export const EVENT_STATUS_AWAITING_HOST_APPROVAL = 'awaiting_host_approval'
export const EVENT_STATUS_APPROVED_BY_HOST = 'approved_by_host'

export function fetchMealTypes(t) {
  return [
    {
      label: t('MealType::Aperitif'),
      value: 'Aperitif',
    },
    {
      label: t('MealType::Breakfast'),
      value: 'Breakfast',
    },
    {
      label: t('MealType::Brunch'),
      value: 'Brunch',
    },
    {
      label: t('MealType::Cooking classes'),
      value: 'Cooking classes',
    },
    {
      label: t('MealType::Dinner'),
      value: 'Dinner',
    },
    {
      label: t('MealType::Food walk'),
      value: 'Food walk',
    },
    {
      label: t('MealType::Lunch'),
      value: 'Lunch',
    },
    {
      label: t('MealType::Tasting'),
      value: 'Tasting',
    },
    {
      label: t('MealType::Tea time'),
      value: 'Tea time',
    },
    {
      label: t('MealType::Picnic'),
      value: 'Picnic',
    },
  ]
}

const descriptionSkeleton = {
  id: undefined,
  language_id: undefined,
  title: undefined,
  order: undefined,
  describes: undefined,
  items: [],
}

export const getDescriptionSkeleton = () => descriptionSkeleton

const descriptionItemSkeleton = {
  id: undefined,
  title: undefined,
  body: undefined,
  type: 'text', // 'text' or 'geometry'
  order: undefined,
}

const shareableEventByStatus = [
  EVENT_STATUS_PUBLISHED,
  EVENT_STATUS_AWAITING_HOST_APPROVAL,
  EVENT_STATUS_APPROVED_BY_HOST,
]

export function isEventPublished(event) {
  return event.status === EVENT_STATUS_PUBLISHED
}

export function isEventShareable(event) {
  return shareableEventByStatus.includes(event.status)
}

export const getDescriptionItemSkeleton = () => descriptionItemSkeleton

export const getDescriptions = ({ descriptions = new List(), describes = '', langId = 0 }) =>
  descriptions.filter(
    (d) => (!describes || d.get('describes') === describes) && (!langId || d.get('language_id') === langId),
  )

export const getEventDescriptionText = ({ descriptions = new List(), langId = 0 }) => {
  const eventDescription = descriptions
    .filter((d) => d.get('describes') === 'event' && (!langId || d.get('language_id') === langId))
    .first()
  if (eventDescription) {
    const item = eventDescription.get('items').first()
    return item ? item.get('body') : undefined
  }
  return undefined
}

const statusDisplayOrder = [
  EVENT_STATUS_PUBLISHED,
  EVENT_STATUS_TO_REVIEW,
  EVENT_STATUS_APPROVED_BY_HOST,
  EVENT_STATUS_AWAITING_HOST_APPROVAL,
  EVENT_STATUS_DRAFT,
  EVENT_STATUS_REJECTED,
]

/**
 * This sort comparator function is intended to sort events to be displayed
 *
 * @param {object} eventA
 * @param {object} eventB
 * @returns {number}
 */
export function statusDisplayComparator(eventA, eventB) {
  const indexStatusA = statusDisplayOrder.indexOf(eventA.status)
  const indexStatusB = statusDisplayOrder.indexOf(eventB.status)

  return indexStatusA - indexStatusB
}

/**
 * @param {object} eventA
 * @param {object} eventB
 * @returns {number}
 */
export function publishedAtComparator(eventA, eventB) {
  if (!eventA.published_at || !eventB.published_at) return 0

  return new Date(eventA.published_at) - new Date(eventB.published_at)
}

// Helpers for create / edit event
export const timeOptions = ({ from = '00:00', to = '23:30' } = {}) => {
  const startTime = formatTimeToFloat(from) * 2
  let endTime = formatTimeToFloat(to) * 2 + 1
  if (endTime < startTime) endTime += 48

  return times(endTime - startTime, (n) => {
    let time = ''
    n = n + startTime > 47 ? parseFloat((n - 48 + startTime) / 2) : parseFloat((n + startTime) / 2)

    if (parseInt(n) < 10) time = '0'
    time += n.toString().replace('.5', ':30')
    if ((n * 10) % 10 === 0) time += ':00'
    return { label: time, value: time }
  })
}

export const rangeOptions = ({ from = 1, to = 101 } = {}) => range(from, to).map((value) => ({ label: value, value }))

/**
 * Get color from event type
 *
 * @param {string} eventType
 * @returns {string}
 */
export function getColorFromEventType(eventType) {
  switch (eventType) {
    case 'Dinner':
      return 'orange'
    case 'Cooking classes':
      return 'purple'
    case 'Food walk':
      return 'pink'
    case 'Tasting':
      return 'pink'
    default:
      return 'orange'
  }
}

export const DEFAULT_AVAILABILITIES = Map({
  mon: true,
  tue: true,
  wed: true,
  thu: true,
  fri: true,
  sat: true,
  sun: true,
})
