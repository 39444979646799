import { schema } from 'normalizr'
import { Record, List, Map } from 'immutable'

export const StripeAccountSchema = new schema.Entity(
  'stripeAccounts',
  {
    // bankAccounts schema is linked in ./_utils.js
  },
  { idAttribute: () => 1 },
)

export const StripeAccountRecord = Record({
  id: null,
  requirements: new Map(),
  bankAccounts: new List(),
  address: new Map(),
  onboardingComplete: false,
})

export const emptyStripeAccount = new StripeAccountRecord()
