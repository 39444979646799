import { screenApi } from 'apis'
import { cleanPayloadEmptyFields } from 'helpers/object'
import { ActionsCreator, mergeMetadata } from '../lib'
import { PlaceSchema } from 'redux/schemas'

export const placesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'places' })

export const createPlace = (payload, query) =>
  placesActions.create({
    url: '/places',
    payload: cleanPayloadEmptyFields(payload),
    query,
    schema: { place: PlaceSchema },
  })

export const deletePlace = (id) =>
  placesActions.delete({
    url: `/places/${id}`,
    deleted: { places: [String(id)] },
  })

export const fetchPlaces = ({ offset = 0, size = 9, eventId }) =>
  placesActions.fetch({
    url: '/places',
    query: {
      event_id: eventId,
      offset,
      size,
    },
    schema: { places: [PlaceSchema] },
  })

export const updatePlace = (id, payload) =>
  placesActions.update({
    url: `/places/${id}`,
    payload: cleanPayloadEmptyFields(payload),
    schema: { place: PlaceSchema },
  })

export const fetchPlace = (id) =>
  placesActions.fetch({
    url: `places/${id}`,
    schema: { place: PlaceSchema },
  })

export const placesCustomHandlers = {
  [placesActions.actionTypes.FETCHED]: mergeMetadata('places'),
}
