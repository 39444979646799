import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { InviteSchema, ScheduleSchema, ScheduleRecord } from 'redux/schemas'
import { getSchedule } from 'redux/selectors'
import { normalize } from 'normalizr'
import moment from 'moment'

export const invitesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'invites' })

export const fetchInvites = (query) =>
  invitesActions.fetch({
    url: '/invites',
    query,
    schema: { invites: [InviteSchema] },
  })

export const fetchInvite = (token, query) => {
  return invitesActions.fetch({
    url: `/invites/${token}`,
    query,
    schema: { invite: InviteSchema },
  })
}

export const createInvite = (payload, query) =>
  invitesActions.create({
    url: '/invites',
    payload,
    query,
    schema: { invite: InviteSchema },
  })

// Due to the heavy usage on the host side, we need to merge schedules manually to ensure the correct data are shown on the host calendar
// Without this, the Lists listed below as "keysToMerge" and "keysToUpdate" are all empty with the exception of the event we are loading the invite for
const handleInviteSchedule = (state, action) => {
  const { entities, result } = action.payload.data
  const normazlizedSchedule = normalize(entities.invites[result.invite].schedule, ScheduleSchema)
  const inviteSchedule = new ScheduleRecord(normazlizedSchedule.entities.schedules[normazlizedSchedule.result])
  const mDate = moment.utc(inviteSchedule.date, 'YYYY-MM-DD')
  const prevSchedule = getSchedule(state, mDate)

  const keysToMerge = [
    'bookable_event_ids',
    'booking_ids',
    'instant_booking_event_ids',
    'opened_event_ids',
    'request_ids',
    'overrides',
  ]
  const keysToUpdate = ['requestable_event_ids', 'closed_event_ids']

  const finalSchedule = prevSchedule.mergeWith((prev, next, key) => {
    if (keysToMerge.includes(key)) {
      return prev.toSet().union(next.toSet()).toList()
    }
    if (keysToUpdate.includes(key)) {
      return prev.toSet().subtract(inviteSchedule.bookable_event_ids).toList()
    }
    return next
  }, inviteSchedule)

  return state.setIn(['entities', 'schedules', mDate.format('YYYY-MM-DD')], finalSchedule)
}

export const invitesCustomHandlers = {
  [invitesActions.actionTypes.FETCHED]: handleInviteSchedule,
  [invitesActions.actionTypes.CREATED]: handleInviteSchedule,
}
