import { schema } from 'normalizr'
import { Record, Map } from 'immutable'

export const StripeCountrySchema = new schema.Entity('stripeCountries')

export const StripeCountryRecord = Record({
  id: null,
  supported_bank_account_currencies: new Map(),
  verification_fields: new Map(),
})

export const emptyStripeCountry = new StripeCountryRecord()
