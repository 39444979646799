import { fromJS } from 'immutable'
import MD5 from 'crypto-js/md5'
import { getGTMDataLayerVars, getGTMInitialized, getGTMThunk } from 'redux/selectors'
import i18n from 'i18n-client'

export const gtmActions = {
  INITIALIZED: '@@gtm/INITIALIZED',
  DATALAYER_PUSH: '@@gtm/DATALAYER_PUSH',
  PUSH_IN_THUNK: '@@gtm/PUSH_IN_THUNK',
  REPLAY_THUNK_ITEM: '@@gtm/REPLAY_THUNK_ITEM',
}

const dataLayerName = 'dataLayer'

const push =
  (data, thunk = null) =>
  (dispatch, getState) => {
    if (typeof window === 'undefined') return
    const state = getState()
    if (!thunk && !getGTMInitialized(state)) thunk = 'waitInit'

    const dataLayer = data.event ? data : { ...data, event: 'dataLayerPush' }
    if (!thunk) {
      window[dataLayerName].push(dataLayer)
    } else if (thunk === 'waitInit') {
      console.warn(`GTM: Layer "${dataLayerName}" not initialized, action will be put in thunk`) // eslint-disable-line no-console
    }

    return dispatch({
      type: thunk ? gtmActions.PUSH_IN_THUNK : gtmActions.DATALAYER_PUSH,
      payload: {
        dataLayer,
        thunk,
      },
    })
  }

const replayThunk =
  (thunkName = 'waitInit') =>
  (dispatch, getState) => {
    const state = getState()
    if (!getGTMInitialized(state)) return

    const thunk = getGTMThunk(state, thunkName)
    if (thunk.size) {
      let replayUserThunk = false
      thunk.forEach((dataLayer) => {
        dispatch({ type: gtmActions.REPLAY_THUNK_ITEM, thunk: thunkName })
        dispatch(push(dataLayer.toJS()))
        replayUserThunk =
          replayUserThunk || (thunkName === 'waitInit' && !!dataLayer.getIn(['user', 'visitorLoginState']))
      })

      if (replayUserThunk) dispatch(replayThunk('user'))
    }
  }

const pushAfterUserIsSet = (data) => (dispatch, getState) => {
  const state = getState()
  const thunkName = getGTMDataLayerVars(state) ? null : 'user'
  return dispatch(push(data, thunkName))
}

export const initDataLayer = () => {
  if (typeof window === 'undefined') return
  window[dataLayerName] = window[dataLayerName] || []

  const gtmStartEvent = { 'gtm.start': new Date().getTime(), event: 'gtm.js' }
  window[dataLayerName].push(gtmStartEvent)
}

export const initGTM = () => (dispatch) => {
  const gtmStartEvent = { 'gtm.start': new Date().getTime(), event: 'gtm.js' }
  dispatch({ type: gtmActions.INITIALIZED, payload: { dataLayer: gtmStartEvent } })
  dispatch(replayThunk('waitInit'))
}

export const GTMError = () => {
  if (!window[dataLayerName]) {
    console.error('GTM load error', err) // eslint-disable-line
    console.warn(`GTM: Layer "${dataLayerName}" not initialized`) // eslint-disable-line no-console
    window[dataLayerName] = []
  }
}

// handlers
const handleInit = (state, { payload }) => {
  return state
    .setIn(['GTM', 'initialized'], true)
    .setIn(['GTM', 'dataLayer'], payload.dataLayer)
    .mergeIn(['GTM', 'dataLayerVars'], payload.dataLayer)
}

const handleDataLayerPush = (state, { payload }) => {
  return state
    .setIn(['GTM', 'dataLayer'], payload.dataLayer) // TODO: check this
    .mergeIn(['GTM', 'dataLayerVars'], payload.dataLayer)
}

const handlePushInThunk = (state, { payload }) => {
  const thunk = getGTMThunk(state, payload.thunk)
  let nextItems = thunk.push(fromJS(payload.dataLayer))
  if (payload.thunk === 'waitInit') {
    // put the init event at the beginning
    nextItems = nextItems.sort((dummy, dataLayer) => dataLayer.get('event') === 'gtm.js')
  }

  return state.mergeIn(['GTM', 'thunks'], { [payload.thunk]: nextItems })
}

const handleReplayThunk = (state, { thunk }) => {
  const replayThunk = getGTMThunk(state, thunk)
  return state.mergeIn(['GTM', 'thunks'], { [thunk]: replayThunk.shift() })
}

export const gtmCustomHandlers = {
  [gtmActions.INITIALIZED]: handleInit,
  [gtmActions.DATALAYER_PUSH]: handleDataLayerPush,
  [gtmActions.PUSH_IN_THUNK]: handlePushInThunk,
  [gtmActions.REPLAY_THUNK_ITEM]: handleReplayThunk,
}

/* --------------
    GTM events
---------------- */
export function gtmPageView() {
  return pushAfterUserIsSet({
    event: 'pageView',
  })
}

export function gtmLangCurrency(currencyIso3) {
  return (dispatch, getState) => {
    const locale = i18n.language
    if (!locale || !currencyIso3) return () => {}
    return dispatch(
      pushAfterUserIsSet({
        langCurrency: `${locale.replace('-', '').toUpperCase()}${currencyIso3.toUpperCase()}`,
      }),
    )
  }
}

// login
export function gtmVisitorSawAuthenticationForm({ type, appearance }) {
  return (dispatch) => {
    dispatch(
      push({
        event: 'eventGA',
        eventCategory: 'user',
        eventAction: 'authentication form',
        eventLabel: `type:${type}, appearance:${appearance}`,
      }),
    )
  }
}

export function gtmUserLoggedIn({ user, country, type: loginType }) {
  // loginType is 'Email' or 'Facebook' or 'Google'
  const data = Object.assign(
    {
      user: {
        visitorId: user.id,
        visitorLoginState: 'logged',
        visitorGender: user.civility === 'MRS' ? 'F' : user.civility === 'MR' ? 'M' : 'Other',
        country,
        newCustomer: 'no',
        email: MD5(user.email).toString(),
      },
    },
    loginType && {
      // if type of login is defined, fill also the event category
      event: 'eventGA',
      eventCategory: 'user',
      eventAction: 'log in',
      eventLabel: loginType,
    },
  )
  return (dispatch) => {
    dispatch(push(data))
    dispatch(replayThunk('user'))
  }
}
export function gtmUserLoggedOut() {
  const data = {
    user: {
      visitorLoginState: 'unlogged',
      email: undefined,
    },
  }
  return (dispatch) => {
    dispatch(push(data))
    dispatch(replayThunk('user'))
  }
}

export function gtmRegistered(profile, registerType, created) {
  //  registerType is 'Email' or 'Facebook' or 'Google'
  return (dispatch) => {
    dispatch(
      push({
        user: {
          visitorId: profile.id,
          visitorLoginState: 'logged',
          visitorGender: profile.civility === 'MRS' ? 'F' : profile.civility === 'MR' ? 'M' : 'Other',
          country: undefined,
          newCustomer: created ? 'yes' : 'no',
          email: MD5(profile.email).toString(),
        },
        event: 'eventGA',
        eventCategory: 'user',
        eventAction: 'registration',
        eventLabel: registerType,
      }),
    )
    dispatch(push({ event: 'socialRegistration' }))
  }
}

// post a meal
export function gtmPostMealStep(step) {
  return (dispatch) => {
    if (step === 0) return // Avoid tracking the edit profile step
    dispatch(
      push({
        event: 'eventGA',
        eventCategory: 'user',
        eventAction: 'post meal',
        eventLabel: String(step),
      }),
    )
  }
}

/**
 * @param  {[type]} translatedSection 'Description' | 'Review'
 */
export function gtmGoogleTranslateUsed(translatedSection) {
  return push({
    event: 'eventGA',
    eventCategory: 'meal',
    eventAction: 'translate',
    eventLabel: translatedSection,
  })
}

// booking confirmations
export function gtmHostConfirmedBooking() {
  return push({
    event: 'eventGA',
    eventCategory: 'meal',
    eventAction: 'Host',
    eventLabel: 'Confirm',
  })
}

export function gtmHostCanceledBooking() {
  return push({
    event: 'eventGA',
    eventCategory: 'meal',
    eventAction: 'Host',
    eventLabel: 'Cancel',
  })
}

export function gtmGuestCanceledBooking() {
  return push({
    event: 'eventGA',
    eventCategory: 'meal',
    eventAction: 'Guest',
    eventLabel: 'Cancel',
  })
}

export function gtmButtonClicked(data) {
  return push({
    event: 'eventGA',
    eventCategory: 'Button',
    eventAction: 'clicked',
    eventLabel: JSON.stringify(data),
  })
}
