import React from 'react'
import ReactDOM from 'react-dom'
import { screenApi } from 'apis'
import settings from 'settings'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './client'
import i18n from './i18n-client'

if (settings.sentry.dsn) {
  Sentry.init({
    dsn: settings.sentry.dsn,
    environment: settings.sentry.environment || 'development',
    release: settings.sentry.release,

    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseFloat(settings.sentry.tracesSampleRate) || 0,
    denyUrls: [
      // Intercom
      /js\.intercomcdn\.com/i,
      // GTM injected scripts
      /twitter/i,
      /hotjar/i,
      /bing/i,
      /googleads/i,
      /stats\.g\.doubleclick\.net/i,
      // Facebook blocked
      /connect\.facebook\.net/i,
      // Cloudflare
      /cdn-cgi\/rum/i, // I believe this is the performance script from Cloudflare
      // Browser extensions
      /extensions\//i,
      /^safari-extension/i,
      /^chrome:\/\//i,
    ],
  })
}

ReactDOM.render(<App />, document.getElementById('content'))

i18n.on('languageChanged', (locale) => {
  screenApi.setHeader('X-Language', locale)
  // TODO: remove this once all occurences of connect() from react-redux are removed because they prevent component rerender...
  ReactDOM.hydrate(<App />, document.getElementById('content'))
})
