import {
  accountCustomHandlers,
  appSettingsCustomHandlers,
  bookingsCustomHandlers,
  browserSettingsCustomHandlers,
  conversationsCustomHandlers,
  eventsCustomHandlers,
  gtmCustomHandlers,
  invitesCustomHandlers,
  modalCustomHandlers,
  notesCustomHandlers,
  notificationsCustomHandlers,
  paymentsCustomHandlers,
  payoutsCustomHandlers,
  placesCustomHandlers,
  repliesCustomHandlers,
  requestsCustomHandlers,
  reviewsCustomHandlers,
  routerCustomHandlers,
  schedulesCustomHandlers,
} from 'redux/actions'

export default {
  ...accountCustomHandlers,
  ...appSettingsCustomHandlers,
  ...bookingsCustomHandlers,
  ...browserSettingsCustomHandlers,
  ...conversationsCustomHandlers,
  ...eventsCustomHandlers,
  ...gtmCustomHandlers,
  ...invitesCustomHandlers,
  ...modalCustomHandlers,
  ...notesCustomHandlers,
  ...notificationsCustomHandlers,
  ...paymentsCustomHandlers,
  ...payoutsCustomHandlers,
  ...placesCustomHandlers,
  ...repliesCustomHandlers,
  ...requestsCustomHandlers,
  ...reviewsCustomHandlers,
  ...routerCustomHandlers,
  ...schedulesCustomHandlers,
}
