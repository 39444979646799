import { createSelector } from 'reselect'
import { getEntities, getEntity } from './_utils'
import { getCurrency } from 'redux/selectors'

export const getReferralWallets = getEntities('referralWallets')
export const getReferralWallet = (state) => getEntity('referralWallets')(state, { id: 1 })

export const getReferralWalletCurrency = createSelector(
  getReferralWallet,
  (state) => state,
  (wallet, state) => getCurrency(state, { id: wallet.currency }),
)
