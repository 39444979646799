import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { FileSchema } from 'redux/schemas'

export const filesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'files' })

export const fetchFiles = (query, config) =>
  filesActions.fetch({
    url: '/files',
    query,
    schema: { files: [FileSchema] },
    ...config,
  })

export const fetchFile = (id, query) =>
  filesActions.fetch({
    url: `/files/${id}`,
    query,
    schema: { file: FileSchema },
  })

export const fetchEventFiles = (eventId, query, config) =>
  filesActions.fetch({
    url: `/events/${eventId}/files`,
    query,
    schema: { files: [FileSchema] },
    ...config,
  })

export const createEventFiles = (eventId, payload) =>
  filesActions.create({
    url: `/events/${eventId}/files`,
    payload,
    schema: { files: [FileSchema] },
  })

export const deleteEventFile = (id, eventId) =>
  filesActions.delete({
    url: `/events/${eventId}/files/${id}`,
    schema: { file: FileSchema },
    deleted: { files: [String(id)] },
  })

export const fetchUserFiles = (userId, query, config) =>
  filesActions.fetch({
    url: `/users/${userId}/files`,
    query,
    schema: { files: [FileSchema] },
    ...config,
  })

export const createUserFiles = (payload) =>
  filesActions.create({
    url: '/files',
    payload,
    schema: { files: [FileSchema] },
  })

export const deleteFile = (id) =>
  filesActions.delete({
    url: `/files/${id}`,
    schema: { file: FileSchema },
    deleted: { files: [String(id)] },
  })

export const createReviewFiles = (reviewId, payload) =>
  filesActions.create({
    url: `/reviews/${reviewId}/files`,
    payload,
    schema: { files: [FileSchema] },
  })
