export const breakpoints = {
  mobile: 400,
  phablet: 550, // xs
  tablet: 750, // sm
  desktop: 1000, // md
  large: 1200, // lg
  xlarge: 1520, // xl
}
breakpoints.xs = 0
breakpoints.sm = breakpoints.tablet
breakpoints.md = breakpoints.destkop
breakpoints.lg = breakpoints.large
breakpoints.xl = breakpoints.xlarge

export function deviceIs(type, width) {
  switch (type) {
    case 'mobile':
      return width < breakpoints.phablet
    case 'phablet':
      return width >= breakpoints.phablet && width < breakpoints.tablet
    case 'tablet':
      return width >= breakpoints.tablet && width < breakpoints.desktop
    case 'desktop':
      return width >= breakpoints.desktop && width < breakpoints.large
    case 'large':
      return width >= breakpoints.large && width < breakpoints.xlarge
    case 'xlarge':
      return width >= breakpoints.xlarge

    case 'xs':
      return width < breakpoints.tablet
    case 'sm':
      return width >= breakpoints.tablet && width < breakpoints.desktop
    case 'md':
      return width >= breakpoints.desktop && width < breakpoints.large
    case 'lg':
      return width >= breakpoints.large && width < breakpoints.xlarge
    case 'xl':
      return width >= breakpoints.xlarge
  }
}
