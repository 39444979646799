import { createSelector } from 'reselect'
import { List, Map } from 'immutable'
import {
  getEntities,
  isFetching,
  isCreating,
  isUpdatingRegex,
  getCreateError,
  getFetchError,
  getUpdateErrorRegex,
  getCreateErrorRegex,
  isCreatingRegex,
} from './_utils'
import { emptyHostApplication } from 'redux/schemas'

// host applications
export const getHostApplications = getEntities('hostApplications')

export const getHostApplication = createSelector(
  getHostApplications,
  (hostApplications) => hostApplications.first() || emptyHostApplication,
)

export const getHostApplicationHost = createSelector(
  getHostApplication,
  (hostApplication) => hostApplication.get('host') || Map(),
)

export const getHostApplicationSampleExperience = createSelector(getHostApplication, (hostApplication) =>
  hostApplication.get('sample_experience'),
)

export const getHostApplicationMetadata = createSelector(
  getHostApplication,
  (hostApplication) => hostApplication.get('metadata') || Map(),
)

export const getHostApplicationLanguages = createSelector(getHostApplicationHost, (host) => {
  const languages = host.get('spoken_languages')
  return languages.size > 0 ? languages : List([{}])
})

export const fetchingHostApplication = (state, id) => isFetching(`/hostApplications/${id}`)(state)
export const updatingHostApplication = isUpdatingRegex(/\/hostApplications/)
export const creatingHostApplication = isCreating('/hostApplications')
export const submittingHostApplication = isCreatingRegex(/\/hostApplications/)

// errors
export const getFetchHostApplicationError = (state, id) => getFetchError(`/hostApplications/${id}`)(state)
export const getCreateHostApplicationError = getCreateError('/hostApplications')
export const getUpdateHostApplicationError = getUpdateErrorRegex(/\/hostApplications/)
export const getSubmitHostApplicationError = getCreateErrorRegex(/\/hostApplications/)
