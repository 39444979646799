import { schema } from 'normalizr'
import { Record } from 'immutable'

export const BankAccountSchema = new schema.Entity('bankAccounts')

export const BankAccountRecord = Record({
  id: null,
  bank_name: null,
  account_holder_name: null,
  last4: null,
  currency: null,
  default_for_currency: null,
})

export const emptyBankAccount = new BankAccountRecord()
