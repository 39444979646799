import { LOCATION_CHANGE } from 'react-router-redux'
import { Map } from 'immutable'

const handleRouteChange = (state, { payload }) => {
  const routerState = state.get('router') || Map()
  const locationBeforeTransitions = routerState.get('locationBeforeTransitions') || null
  let prevPage = routerState.get('prevPage')

  if (locationBeforeTransitions && payload.pathname !== locationBeforeTransitions.pathname) {
    prevPage = locationBeforeTransitions
  }

  return state
    .setIn(['router', 'locationBeforeTransitions'], payload)
    .setIn(['router', 'prevLocation'], locationBeforeTransitions)
    .setIn(['router', 'prevPage'], prevPage)
}

export const routerCustomHandlers = {
  [LOCATION_CHANGE]: handleRouteChange,
}
