import { createSelector } from 'reselect'
import {
  getEntities,
  getEntity,
  isFetching,
  isCreating,
  isUpdating,
  isDeleting,
  getCreateError,
  getUpdateError,
  getDeleteError,
  getFetchError,
  getObjId,
} from './_utils'
import { getCurrentUser, getEvent } from 'redux/selectors'

export const getWishlists = getEntities('wishlists')
export const getWishlist = getEntity('wishlists')

export const getWishlistUser = createSelector(
  getWishlist,
  (state) => state,
  (wishlist, state) => getCurrentUser(state, getObjId(wishlist, 'user')),
)

export const getWishlistEvents = createSelector(
  getWishlist,
  (state) => state,
  (wishlist, state) => wishlist.events.map((id) => getEvent(state, id)).filter((e) => e.status === 'approved'),
)

export const getMyWishlists = createSelector(getWishlists, getCurrentUser, (wishlists, currentUser) =>
  wishlists.filter((wishlist) => getObjId(wishlist, 'user') === String(currentUser.id)),
)

export const getIsEventInMyWhishlists = createSelector(
  getMyWishlists,
  (state, eventId) => eventId,
  (wishlists, eventId) => {
    return wishlists.some((wishlist) => wishlist.events.some((id) => id === eventId))
  },
)

export const fetchingWishlists = isFetching('/wishlists')
export const fetchingWishlist = (state, id) => isFetching(`/wishlists/${id}`)(state)
export const updatingWishlist = (state, id) => isUpdating(`/wishlists/${id}`)(state)
export const creatingWishlist = isCreating('/wishlists')
export const deletingWishlist = (state, id) => isDeleting(`/wishlists/${id}`)(state)

export const getFetchWishlistError = (state, id) => getFetchError(`/wishlists/${id}`)(state)
export const getDeleteWishlistError = (state, id) => getDeleteError(`/wishlists/${id}`)(state)
export const getCreateWishlistError = getCreateError('/wishlists')
export const getUpdateWishlistError = (state, id) => getUpdateError(`/wishlists/${id}`)(state)
