import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { CampaignSchema } from 'redux/schemas'

export const campaignsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'campaigns' })

export const createCampaign = (payload) =>
  campaignsActions.create({
    url: '/campaigns',
    payload,
    schema: { campaign: CampaignSchema },
  })

export const deleteCampaign = (id) =>
  campaignsActions.delete({
    url: `/campaigns/${id}`,
    schema: { campaign: CampaignSchema },
    deleted: { campaigns: [String(id)] },
  })

export const fetchCampaigns = (query) =>
  campaignsActions.fetch({
    url: '/campaigns',
    query,
    schema: { campaigns: [CampaignSchema] },
  })

export const fetchReferralCampaign = () =>
  campaignsActions.fetch({
    url: '/campaigns/referral',
    schema: { campaign: CampaignSchema },
  })

export const shareReferralCodeByEmail = (payload) =>
  campaignsActions.create({
    url: '/campaigns/referral/share',
    payload,
    schema: { campaign: CampaignSchema },
  })
