import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching } from './_utils'
import { List } from 'immutable'
import { getEvent } from 'redux/selectors'

export const getPlaylists = getEntities('playlists')
export const getPlaylist = getEntity('playlists')

export const fetchingPlaylist = (state, id) => isFetching(`/playlists/${id}`)(state)

export const getPlaylistEvents = createSelector(
  getPlaylist,
  (state) => state,
  (playlist, state) => playlist.getIn(['search', 'event_ids'], new List()).map((id) => getEvent(state, id)),
)
