import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'

import reducer from 'redux/reducer'

// Inspired by Redux-Thunk: https://github.com/gaearon/redux-thunk
function thunkCatchPromise(activePromises) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        const result = action(dispatch, getState)

        const isPromise = typeof result === 'object' && typeof result.then === 'function'
        if (isPromise) {
          const activePromise = result.catch((e) => {
            // Ignore errors because they're handled in actions
          })
          // Add Promise to activeActions
          activePromises.push(activePromise)
          // Remove Promise from activeActions when finished
          activePromise.then(() => activePromises.splice(activePromises.indexOf(activePromise), 1))
        }
        return result
      }

      return next(action)
    }
}

const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action)
  } catch (err) {
    console.error(`Error caught in reducers while dispatching ${action.type}`, err) // eslint-disable-line no-console
    throw err
  }
}
export default ({ preloadedState, historyStrategy }) => {
  // List of actions promises currently running
  // Useful to know when all actions are finished
  const activePromises = []

  // Middlewares
  const middlewares = [thunkCatchPromise(activePromises), routerMiddleware(historyStrategy)]
  if (process.env.NODE_ENV !== 'production') middlewares.push(crashReporter)

  // Store Enhancers
  const composeEnhancers = (__DEVTOOLS__ && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  // Create store
  const store = createStore(
    reducer,
    // Preloaded state for server side rendering
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )
  return { store, activePromises }
}
