import { createSelector } from 'reselect'
import { getUser, getFile, getEventWithOverrides, getPlace } from 'redux/selectors'

import {
  getEntities,
  getEntity,
  getMetadata,
  getEntityOrder,
  isFetching,
  getObjId,
  isDeleting,
  isUpdating,
  getFetchError,
  getUpdateError,
  getCreateError,
  isCreating,
  getDeleteError,
} from './_utils'

// entity getters
export const getBookings = getEntities('bookings')
export const getBooking = getEntity('bookings')

export const getBookingGuest = createSelector(
  getBooking,
  (state) => state,
  (booking, state) => getUser(state, getObjId(booking, 'user')),
)

export const getBookingGuestAvatar = createSelector(
  getBookingGuest,
  (state) => state,
  (user, state) => getFile(state, getObjId(user, 'avatar')),
)

export const getBookingEvent = createSelector(
  getBooking,
  (state) => state,
  (booking, state) => getEventWithOverrides(state, getObjId(booking, 'event'), booking.date),
)

export const getBookingHost = createSelector(
  getBookingEvent,
  (state) => state,
  (event, state) => getUser(state, getObjId(event, 'user')),
)

export const getBookingPlace = createSelector(
  getBooking,
  (state) => state,
  (booking, state) => getPlace(state, getObjId(booking, 'place')),
)

// metadatas getters
export const getBookingsMetadata = getMetadata('bookings')
export const getBookingsList = createSelector(
  getEntityOrder('bookings'),
  (state) => state,
  (ids, state) => ids.map((id) => getBooking(state, id)),
)

// errors
export const getFetchBookingsError = getFetchError('/bookings')
export const getFetchBookingError = (state, id) => getFetchError(`/bookings/${id}`)(state)
export const getUpdateBookingError = (state, id) => getUpdateError(`/bookings/${id}`)(state)
export const getDeleteBookingError = (state, id) => getDeleteError(`/bookings/${id}`)(state)
export const getCreateBookingError = getCreateError('/bookings')
export const getReportBookingError = (state, id) => getUpdateError(`/bookings/${id}/report_issue`)(state)

export const getNotifyCoguestError = (state, id) => getCreateError(`/bookings/${id}/coguests`)(state)

// notifiers
export const fetchingBookings = isFetching('/bookings')
export const creatingBooking = isCreating('/bookings')
export const fetchingBooking = (state, id) => isFetching(`/bookings/${id}`)(state)
export const updatingBooking = (state, id) => isUpdating(`/bookings/${id}`)(state)
export const deletingBooking = (state, id) => isDeleting(`/bookings/${id}`)(state)

export const notifyingCoguest = (state, id) => isCreating(`/bookings/${id}/coguests`)(state)
