import { ActionsCreator, clearHandler, chainReducers } from '../lib'
import { screenApi } from 'apis'
import { loadStatsForIntercom, intercomLogout } from 'intercom'
import { COOKIE_KEYS, loadCookie, saveCookie, deleteCookie } from 'helpers/cookies'
import { urlFactory } from 'helpers/url'
import { CurrentUserSchema } from '../schemas'
import {
  fetchReferralWallet,
  fetchWishlists,
  fetchCurrentUsers,
  fetchNotifications,
  toggleLoginModal,
  toggleRegisterModal,
  setRegisterModalFormType,
  setAppCurrency,
  gtmUserLoggedIn,
  gtmUserLoggedOut,
  gtmRegistered,
  setAppLocale,
  appInitialised,
} from 'redux/actions'
import { getCurrentUser, getCurrentUserCurrency, getCurrentUserLanguage, getLocation } from 'redux/selectors'

export const authActions = {
  LOGOUT: '@@account/LOGOUT',
}

export const accountActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'account' })

// RENEW PASSWORD
export const createRenewPassword = (email) =>
  accountActions.create({
    url: '/password_renewal',
    payload: { email },
    schema: { user: CurrentUserSchema },
  })

export const updateRenewPassword = (token, password) => (dispatch) =>
  dispatch(
    accountActions.update({
      url: '/password_renewal',
      payload: { password, validation_token: token },
      schema: { user: CurrentUserSchema },
    }),
  )
    .then((result) => dispatch(setAuthenticationData({ type: 'email', result })))
    .catch((err) => {
      dispatch(removeAuthenticationData())
      throw err
    })

// VALIDATE ACCOUNT
export const validateAccount = (email, token) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/accounts/validate',
      payload: { email, validation_token: token },
      schema: { user: CurrentUserSchema },
    }),
  )
    .then((result) => dispatch(setAuthenticationData({ type: 'email', result })))
    .catch((err) => {
      dispatch(removeAuthenticationData())
      throw err
    })
}

// AUTH
const setCookies = (token, language, rememberMe) => {
  screenApi.setHeader('Authorization', `JWT ${token}`)
  screenApi.setHeader('X-Language', language.locale)

  saveCookie({ name: COOKIE_KEYS.TOKEN, value: token, duration: 3600 * 24 * 60 })
  saveCookie({
    name: COOKIE_KEYS.NEWSLETTER_SIGNUP,
    value: true,
    duration: 365 * 24 * 3600,
  })
  saveCookie({
    name: COOKIE_KEYS.REMEMBER_ME_AUTHENTICATION,
    value: rememberMe,
    duration: 3600 * 24 * 90,
  })
}

export const handleRegister =
  (type, { payload: { data } }) =>
  (dispatch, getState) => {
    const token = data.result.token
    if (!data.result.user || !token) throw new Error(`Missing data after registering with ${type}`)
    const state = getState()
    const language = getCurrentUserLanguage(state)
    const rememberMe = true
    return setCookies(token, language, rememberMe)
  }

export const setAuthenticationData =
  ({
    type,
    result: {
      payload: { data },
    },
    cookies,
  }) =>
  (dispatch, getState) => {
    const token = data.result.token || (cookies && cookies.cookieToken)
    if (!data.result.user || !token) throw new Error(`Missing data after ${type} login`)

    const state = getState()
    const user = getCurrentUser(state)
    const currency = getCurrentUserCurrency(state)
    const language = getCurrentUserLanguage(state)

    const rememberMe = cookies && cookies.cookieRememberMe
    setCookies(token, language, rememberMe)

    dispatch(setAppCurrency(currency.iso_3))
    dispatch(setAppLocale(language.locale))

    dispatch(fetchNotifications(0))
    dispatch(fetchReferralWallet())
    dispatch(fetchWishlists())
    dispatch(gtmUserLoggedIn({ user, country: user.country, type }))
    dispatch(toggleLoginModal({ show: false }))
    dispatch(toggleRegisterModal(false))
    screenApi.get('/users/stats').then(({ data: stats }) => {
      loadStatsForIntercom(user, stats)
    })
  }

export const removeAuthenticationData =
  (redirect = true) =>
  (dispatch, getState) => {
    const state = getState()
    if (redirect && (getLocation(state).pathname || '').startsWith('/dashboard')) {
      window.location = urlFactory.web.home()
    }
    intercomLogout()
    return dispatch(gtmUserLoggedOut())
  }

// REGISTER
export const registerWithEmail = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/register',
      payload,
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    const {
      payload: { payload, data },
    } = result
    const user = { ...payload, id: data.result.user }
    dispatch(handleRegister('email', result))
    dispatch(gtmRegistered(user, payload.type, data.result.created))
    return dispatch(toggleRegisterModal(false))
  })
}

// LOGIN
export const loginWithEmail = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login',
      payload,
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) =>
    dispatch(
      setAuthenticationData({
        type: 'email',
        result,
        cookies: { cookieRememberMe: payload.rememberMe },
      }),
    ),
  )
}

export const authByCookie = () => async (dispatch) => {
  screenApi.removeHeader('Authorization')
  const cookieToken = loadCookie(COOKIE_KEYS.TOKEN)
  const cookieRememberMe = loadCookie(COOKIE_KEYS.REMEMBER_ME_AUTHENTICATION) === 'true'
  const cookieIsAdmin = loadCookie(COOKIE_KEYS.VIZEAT_ADMIN) === 'true'
  if (!cookieToken || (!cookieIsAdmin && !cookieRememberMe)) {
    dispatch(appInitialised())
    return dispatch(removeAuthenticationData(false))
  }
  try {
    const result = await dispatch(
      fetchCurrentUsers({
        headers: Object.assign(screenApi.config.headers, { authorization: `JWT ${cookieToken}` }),
      }),
    )
    dispatch(appInitialised())
    return dispatch(
      setAuthenticationData({
        type: 'cookie',
        result,
        cookies: { cookieToken, cookieRememberMe },
      }),
    )
  } catch (e) {
    dispatch(removeAuthenticationData())
    throw e
  }
}

// SOCIAL
export const loginWithFacebook = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login/facebook',
      payload: {
        ...payload,
        access_token: payload.authResponse.accessToken,
      },
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    if (result.payload.error) return
    if (result.payload.data.result.created) {
      // is new, register
      dispatch(toggleRegisterModal(true, true))
      dispatch(handleRegister('facebook', result))
      return dispatch(setRegisterModalFormType('facebook'))
    } else {
      // is login
      dispatch(toggleLoginModal({ show: false }))
      return dispatch(setAuthenticationData({ type: 'facebook', result }))
    }
  })
}

export const loginWithGoogle = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login/google',
      payload: {
        ...payload,
        access_token: payload.authResponse.access_token,
      },
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    if (result.payload.error) return
    if (result.payload.data.result.created) {
      // is new, register
      dispatch(toggleRegisterModal(true, true))
      dispatch(handleRegister('google', result))
      return dispatch(setRegisterModalFormType('google'))
    } else {
      // is login
      dispatch(toggleLoginModal({ show: false }))
      return dispatch(setAuthenticationData({ type: 'google', result }))
    }
  })
}

export const loginWithWeibo = (code) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login/weibo_new',
      payload: { code },
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    if (result.payload.error) return
    if (result.payload.data.result.created) {
      // is new, register
      dispatch(toggleRegisterModal(true, true))
      dispatch(handleRegister('weibo', result))
      dispatch(setRegisterModalFormType('weibo'))
    } else {
      // is login
      dispatch(toggleLoginModal({ show: false }))
      dispatch(setAuthenticationData({ type: 'weibo', result }))
    }
  })
}

// LOGOUT
export const logout = () => (dispatch, getState) => {
  dispatch(removeAuthenticationData())
  return dispatch({ type: authActions.LOGOUT })
}

const handleLogout = (state, action) => {
  deleteCookie(COOKIE_KEYS.TOKEN)
  deleteCookie(COOKIE_KEYS.REMEMBER_ME_AUTHENTICATION)
  screenApi.removeHeader('Authorization')
  return chainReducers(clearHandler('currentUsers'), clearHandler('stripeAccounts'))(state)
}

export const accountCustomHandlers = {
  [authActions.LOGOUT]: handleLogout,
}
